import React from 'react'

const SvgSdWanFeature4 = props => (
  <svg id='sd-wan-feature4_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.sd-wan-feature4_svg__st0{fill:#fd5c1f}'}</style>
    <g id='sd-wan-feature4_svg__\u7A33\u5B9A\u53EF\u9760-48x48'>
      <ellipse
        id='sd-wan-feature4_svg__Oval-33'
        transform='rotate(-45.001 24.759 24.988)'
        className='sd-wan-feature4_svg__st0'
        cx={24.76}
        cy={24.99}
        rx={22}
        ry={7}
      />
      <path
        id='sd-wan-feature4_svg__Fill-2_6_'
        d='M44.29 34.19c1.92 4.85 2 8.68.16 10.49A5.389 5.389 0 0140.5 46c-3.77 0-8.81-2-14-5.47 1-.72 1.94-1.5 2.9-2.3 1.8 1.28 4.3.85 5.58-.95.93-1.31.98-3.05.14-4.41 1.84-1.93 3.57-3.98 5.16-6.12 1.59 2.34 2.93 4.83 4 7.44h.01zm-13.27 2.66c-.15-.06-.3-.14-.43-.24h-.05c-.11-.09-.22-.2-.31-.31-.21-.28-.34-.6-.39-.95-.04-.11-.06-.23-.08-.35 0-1.1.89-2 1.99-2.01 1.1-.01 2 .89 2.01 1.99.01 1.1-.89 2-1.99 2.01-.26 0-.51-.05-.75-.14zm-6.26 2.47a63.903 63.903 0 01-7.73-6.59c-2.4-2.4-4.61-4.98-6.6-7.73 3.99-5.5 8.83-10.33 14.33-14.32 2.75 1.99 5.33 4.19 7.73 6.59 2.4 2.4 4.61 4.98 6.6 7.73a63.497 63.497 0 01-5.42 6.5c-.58-.32-1.24-.5-1.91-.5a4.014 4.014 0 00-3.99 4.02c0 .57.13 1.13.37 1.65-1.12.94-2.25 1.84-3.38 2.65zM9.02 46c-1.44.11-2.86-.37-3.95-1.32C2.42 42 3.83 34.89 9.22 26.75a66.11 66.11 0 006.39 7.4c2.3 2.31 4.77 4.44 7.39 6.38C17.84 44 12.76 46 9.02 46zM5.24 15.81C3.32 11 3.26 7.13 5.08 5.32 6.17 4.37 7.58 3.9 9.02 4c1.99.07 3.96.48 5.81 1.22a3.998 3.998 0 006.93 3.44c.43.26.86.52 1.29.81-5.25 3.9-9.91 8.54-13.82 13.78a41.28 41.28 0 01-3.99-7.44zM20.76 6c0 .15-.02.3-.06.45v.12c-.03.1-.07.2-.11.3l-.1.13c-.06.1-.12.19-.19.28l-.06.07c-.09.1-.19.2-.3.28-.34.25-.76.37-1.18.37a2 2 0 112-2zM40.5 4c1.44-.11 2.86.37 3.95 1.32 1.82 1.82 1.76 5.64-.16 10.49-1.07 2.61-2.41 5.1-4 7.44-1.94-2.62-4.08-5.1-6.39-7.4-2.3-2.31-4.77-4.44-7.39-6.38C31.68 6 36.76 4 40.5 4zm5.36-.1A7.343 7.343 0 0040.5 2c-4.26 0-9.91 2.24-15.74 6.23-.71-.48-1.41-.93-2.1-1.36.43-2.17-.98-4.27-3.15-4.7-1.39-.27-2.81.2-3.75 1.25A19.49 19.49 0 009.02 2c-1.97-.12-3.9.57-5.36 1.9C-.1 7.66 1.91 16.12 7.98 25 1.91 33.88-.1 42.34 3.66 46.1A7.343 7.343 0 009.02 48c4.26 0 9.9-2.24 15.74-6.23 5.83 4 11.48 6.23 15.74 6.23 1.97.12 3.9-.57 5.36-1.9 3.76-3.76 1.75-12.22-4.32-21.1 6.07-8.88 8.08-17.34 4.32-21.1z'
        fill='#334966'
      />
      <circle id='sd-wan-feature4_svg__Oval-32' className='sd-wan-feature4_svg__st0' cx={18.76} cy={5.99} r={2} />
    </g>
  </svg>
)

export default SvgSdWanFeature4
