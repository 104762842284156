import styled, { css } from 'styled-components'

export const FeatureSection = styled.div`
  ${({ theme: { color } }) => css`
    padding: 60px 0 78px 0;
    background-color: ${color.base};
    .jJuBUt {
      min-height: 120px;
    }
  `}
`
export const ArchSection = styled.div`
  padding: 63px 0 40px 0;
  svg {
    display: block;
    width: 60%;
    margin: 0 auto;
  }
`
export const NetworkBigBox = styled.div`
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 20px 0px rgba(37, 42, 58, 0.12);
`

export const NetworkBox = styled.div`
  width: 100%;
  background-color: white;
  div:nth-child(2n) {
    background-color: #f5f7fb;
  }
`
export const NetworkBoxOne = styled.div`
  width: 100%;
  height: 50px;
  background-color: #fd5c1f;
  span {
    display: inline-block;
    width: 300px;
    text-align: left;
    line-height: 50px;
    color: white;
    padding-left: 45px;
  }
`

export const NetworkBoxTwo = styled.div`
  width: 100%;
  height: 50px;
  span {
    display: inline-block;
    width: 300px;
    text-align: left;
    line-height: 50px;
    color: #8796aa;
    padding-left: 45px;
  }
  span:nth-child(2) {
    color: #fd5c1f;
  }
`
