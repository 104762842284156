import React from 'react'

const SvgBanner = props => (
  <svg
    id='banner_svg__Layer_1'
    x={0}
    y={0}
    viewBox='0 0 460 400'
    xmlSpace='preserve'
    enableBackground='new 0 0 460 400'
    {...props}
  >
    <style>
      {
        '.banner_svg__st16{fill:#f3f6ff}.banner_svg__st17{fill:none;stroke:#000;stroke-miterlimit:10}.banner_svg__st182{fill:#012860}'
      }
    </style>
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter'
        filterUnits='userSpaceOnUse'
        x={131.66}
        y={319.19}
        width={100.06}
        height={57.79}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={131.66} y={319.19} width={100.06} height={57.79} id='banner_svg__SVGID_1_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_1_'
            filterUnits='userSpaceOnUse'
            x={131.66}
            y={319.19}
            width={100.06}
            height={57.79}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_2_'
          gradientUnits='userSpaceOnUse'
          x1={131.657}
          y1={348.083}
          x2={231.716}
          y2={348.083}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.124} stopColor='#f6f6f6' />
          <stop offset={0.326} stopColor='#ddd' />
          <stop offset={0.582} stopColor='#b6b6b6' />
          <stop offset={0.878} stopColor='#7f7f7f' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M133.51 350.64l43.7 25.27c2.47 1.43 6.48 1.43 8.95 0l43.7-25.26c2.47-1.43 2.47-3.74 0-5.17l-43.67-25.21c-2.47-1.43-6.48-1.43-8.95 0l-43.73 25.21c-2.47 1.42-2.47 3.73 0 5.16z'
          mask='url(#banner_svg__SVGID_1_)'
          fill='url(#banner_svg__SVGID_2_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_3_'
      gradientUnits='userSpaceOnUse'
      x1={131.657}
      y1={348.083}
      x2={231.716}
      y2={348.083}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.517} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M133.51 350.64l43.7 25.27c2.47 1.43 6.48 1.43 8.95 0l43.7-25.26c2.47-1.43 2.47-3.74 0-5.17l-43.67-25.21c-2.47-1.43-6.48-1.43-8.95 0l-43.73 25.21c-2.47 1.42-2.47 3.73 0 5.16z'
      fill='url(#banner_svg__SVGID_3_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_2_'
        filterUnits='userSpaceOnUse'
        x={262.88}
        y={223.76}
        width={154.74}
        height={89.36}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={262.88} y={223.76} width={154.74} height={89.36} id='banner_svg__SVGID_4_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_2_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_3_'
            filterUnits='userSpaceOnUse'
            x={262.88}
            y={223.76}
            width={154.74}
            height={89.36}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_5_'
          gradientUnits='userSpaceOnUse'
          x1={262.882}
          y1={268.442}
          x2={417.619}
          y2={268.442}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M265.75 272.39l67.59 39.07c3.82 2.21 10.01 2.21 13.84 0l67.59-39.07c3.82-2.21 3.82-5.79 0-7.99l-67.53-38.99c-3.82-2.21-10.02-2.21-13.84 0l-67.63 38.99c-3.84 2.21-3.84 5.79-.02 7.99z'
          mask='url(#banner_svg__SVGID_4_)'
          fill='url(#banner_svg__SVGID_5_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_6_'
      gradientUnits='userSpaceOnUse'
      x1={262.882}
      y1={268.442}
      x2={417.619}
      y2={268.442}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M265.75 272.39l67.59 39.07c3.82 2.21 10.01 2.21 13.84 0l67.59-39.07c3.82-2.21 3.82-5.79 0-7.99l-67.53-38.99c-3.82-2.21-10.02-2.21-13.84 0l-67.63 38.99c-3.84 2.21-3.84 5.79-.02 7.99z'
      fill='url(#banner_svg__SVGID_6_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_4_'
        filterUnits='userSpaceOnUse'
        x={71}
        y={196.71}
        width={104.02}
        height={55.43}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={71} y={196.71} width={104.02} height={55.43} id='banner_svg__SVGID_7_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_4_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_5_'
            filterUnits='userSpaceOnUse'
            x={71}
            y={196.71}
            width={104.02}
            height={55.43}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_8_'
          gradientUnits='userSpaceOnUse'
          x1={71}
          y1={224.426}
          x2={175.018}
          y2={224.426}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M72.93 226.88l45.43 24.24c2.57 1.37 6.73 1.37 9.3 0l45.43-24.23c2.57-1.37 2.57-3.59 0-4.96l-45.39-24.18c-2.57-1.37-6.74-1.37-9.3 0l-45.46 24.19c-2.58 1.35-2.58 3.57-.01 4.94z'
          mask='url(#banner_svg__SVGID_7_)'
          fill='url(#banner_svg__SVGID_8_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_9_'
      gradientUnits='userSpaceOnUse'
      x1={71}
      y1={224.426}
      x2={175.018}
      y2={224.426}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M72.93 226.88l45.43 24.24c2.57 1.37 6.73 1.37 9.3 0l45.43-24.23c2.57-1.37 2.57-3.59 0-4.96l-45.39-24.18c-2.57-1.37-6.74-1.37-9.3 0l-45.46 24.19c-2.58 1.35-2.58 3.57-.01 4.94z'
      fill='url(#banner_svg__SVGID_9_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_6_'
        filterUnits='userSpaceOnUse'
        x={198.27}
        y={267.73}
        width={66.62}
        height={38.48}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={198.27} y={267.73} width={66.62} height={38.48} id='banner_svg__SVGID_10_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_6_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_7_'
            filterUnits='userSpaceOnUse'
            x={198.27}
            y={267.73}
            width={66.62}
            height={38.48}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_11_'
          gradientUnits='userSpaceOnUse'
          x1={198.274}
          y1={286.965}
          x2={264.897}
          y2={286.965}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M199.51 288.67l29.1 16.82c1.64.95 4.31.95 5.96 0l29.1-16.82c1.64-.95 1.64-2.49 0-3.44l-29.07-16.79c-1.65-.95-4.31-.95-5.96 0l-29.12 16.79c-1.66.95-1.66 2.49-.01 3.44z'
          mask='url(#banner_svg__SVGID_10_)'
          fill='url(#banner_svg__SVGID_11_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_12_'
      gradientUnits='userSpaceOnUse'
      x1={198.274}
      y1={286.965}
      x2={264.897}
      y2={286.965}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M199.51 288.67l29.1 16.82c1.64.95 4.31.95 5.96 0l29.1-16.82c1.64-.95 1.64-2.49 0-3.44l-29.07-16.79c-1.65-.95-4.31-.95-5.96 0l-29.12 16.79c-1.66.95-1.66 2.49-.01 3.44z'
      fill='url(#banner_svg__SVGID_12_)'
    />
    <path
      className='banner_svg__st16'
      d='M360.53 211.41c0 2.59 1.38 4.98 3.62 6.27l87.97 50.78c1.16.67 2.44.69 3.53.26.01 0 .02 0 .02-.01l.53-.26c.21-.13.42-.27.6-.43.32-.27.6-.59.82-.96l.09-.15c.31-.57.49-1.23.49-1.95v-.6l-.01-82.07c0-2.65-1.41-5.09-3.7-6.41l-87.36-50.44-1.94-1.12c-.35-.2-.72-.33-1.08-.38a2.82 2.82 0 00-.87-.01c-.18.02-.35.06-.52.11l-.15.06-.69.35-.01.01c-.78.54-1.32 1.44-1.32 2.53l-.02 84.42z'
    />
    <path className='banner_svg__st17' d='M370.15 143.95l.27-.11.15-.06c-.04.01-.07.02-.11.04-.11.03-.21.08-.31.13z' />
    <linearGradient
      id='banner_svg__SVGID_13_'
      gradientUnits='userSpaceOnUse'
      x1={570.315}
      y1={215.281}
      x2={594.734}
      y2={215.281}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={1} stopColor='#bfdcff' />
    </linearGradient>
    <path
      d='M452.04 179.14l-20.15-11.63c-.97-.56-2.19.14-2.19 1.26v78.74c0 2.04 1.09 3.93 2.86 4.96l18.12 10.46c1.53.88 3.44-.22 3.44-1.98l.01-78.2a4.2 4.2 0 00-2.09-3.61z'
      fill='url(#banner_svg__SVGID_13_)'
    />
    <linearGradient
      id='banner_svg__SVGID_14_'
      gradientUnits='userSpaceOnUse'
      x1={574.384}
      y1={187.43}
      x2={590.66}
      y2={187.43}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M450.05 184.02l-.01 15.39c0 .93-1 1.51-1.81 1.04l-13.26-7.66c-.74-.43-1.2-1.22-1.2-2.08v-15.18c0-.99 1.07-1.61 1.93-1.11l13.2 7.61c.71.41 1.15 1.17 1.15 1.99z'
      fill='url(#banner_svg__SVGID_14_)'
    />
    <linearGradient
      id='banner_svg__SVGID_15_'
      gradientUnits='userSpaceOnUse'
      x1={598.799}
      y1={176.744}
      x2={659.839}
      y2={176.744}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M423.14 162.48l-55.37-31.98c-1.41-.82-3.18.2-3.18 1.83v54.63c0 1.72.92 3.31 2.41 4.17l55.13 31.82c1.56.9 3.5-.22 3.5-2.02l-.01-54.15c.01-1.77-.94-3.41-2.48-4.3z'
      fill='url(#banner_svg__SVGID_15_)'
    />
    <linearGradient
      id='banner_svg__SVGID_16_'
      gradientUnits='userSpaceOnUse'
      x1={598.801}
      y1={221.261}
      x2={659.839}
      y2={221.261}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M425.63 231.44l.01 13.54c0 1.56-1.69 2.53-3.04 1.75l-56.41-32.58a3.192 3.192 0 01-1.59-2.75v-14.41c0-1.13 1.22-1.84 2.2-1.27l57.31 33.09c.94.54 1.52 1.54 1.52 2.63z'
      fill='url(#banner_svg__SVGID_16_)'
    />
    <path className='banner_svg__st17' d='M370.42 143.84l.15-.06c-.04.01-.07.02-.11.04l-.04.02z' />
    <path
      d='M362.6 124.06c.04-.02.07-.03.11-.04.17-.05.35-.09.52-.11.28-.04.58-.04.87.01.37.05.73.18 1.08.38l1.94 1.12 87.36 50.44a7.404 7.404 0 013.7 6.41l.01 82.07v.6c0 .72-.18 1.38-.49 1.95l-.09.15c-.22.36-.5.69-.82.96-.19.16-.39.31-.6.43l1.56-.77c.68-.34 1.27-.87 1.67-1.53.36-.6.58-1.31.58-2.1v-.6l-.01-82.07c0-2.65-1.41-5.09-3.7-6.41l-87.36-50.44-1.94-1.12c-.81-.47-1.69-.52-2.48-.27l-.42.17-.19.1-1.3.67z'
      fill='#cad8fc'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_8_'
        filterUnits='userSpaceOnUse'
        x={371.9}
        y={151.83}
        width={43.8}
        height={47.77}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={371.9} y={151.83} width={43.8} height={47.77} id='banner_svg__SVGID_17_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_8_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_9_'
            filterUnits='userSpaceOnUse'
            x={371.9}
            y={151.83}
            width={43.8}
            height={47.77}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_18_'
          gradientUnits='userSpaceOnUse'
          x1={608.739}
          y1={175.721}
          x2={652.535}
          y2={175.721}
          gradientTransform='matrix(-1 0 0 1 1024.435 0)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M415.53 199.61c.16-3.11.82-18.71-2.44-20.59-.45-.26-.95-.32-1.42-.16-1.62.54-2.86 3.43-4.3 6.78-1.15 2.68-2.58 6.01-3.8 6.42-.19.06-.36.04-.56-.07-1.73-1-1.95-8.75-2.12-14.97-.25-9.13-.57-14.77-2.74-16.03-.45-.26-.93-.3-1.4-.11-1.73.7-2.86 4.45-4.56 10.51-1.32 4.71-2.97 10.58-4.72 11.29-.16.07-.39.12-.74-.08-2.36-1.36-2.2-9.52-2.08-16.08.16-8.16.14-13.23-1.99-14.46-.58-.34-1.07-.23-1.38-.08-1.69.8-2.49 5.17-3.61 12.27-.72 4.54-1.7 10.76-2.92 11.34-.04.02-.09.04-.24-.04-1.56-.9-1.59-7.96-1.11-12.69l-1.22-.12c-.2 2.03-1.08 12.25 1.72 13.87.58.34 1.07.23 1.38.08 1.68-.8 2.48-5.17 3.6-12.26.72-4.54 1.7-10.77 2.92-11.35.04-.02.09-.05.24.04 1.61.93 1.48 7.54 1.37 13.37-.15 7.94-.29 15.44 2.69 17.16.6.34 1.22.4 1.82.16 2.3-.94 3.82-6.36 5.44-12.1 1.15-4.09 2.58-9.19 3.84-9.7.09-.04.18-.04.32.04 1.74 1 1.96 8.76 2.13 15 .25 9.12.57 14.75 2.74 16 .5.29 1.04.35 1.56.17 1.75-.58 3.11-3.75 4.55-7.1 1.1-2.55 2.46-5.74 3.56-6.1.15-.05.27-.03.42.05 1.91 1.1 2.22 12.04 1.82 19.46l1.23.08z'
          mask='url(#banner_svg__SVGID_17_)'
          fill='url(#banner_svg__SVGID_18_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_19_'
      gradientUnits='userSpaceOnUse'
      x1={608.739}
      y1={175.721}
      x2={652.535}
      y2={175.721}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M415.53 199.61c.16-3.11.82-18.71-2.44-20.59-.45-.26-.95-.32-1.42-.16-1.62.54-2.86 3.43-4.3 6.78-1.15 2.68-2.58 6.01-3.8 6.42-.19.06-.36.04-.56-.07-1.73-1-1.95-8.75-2.12-14.97-.25-9.13-.57-14.77-2.74-16.03-.45-.26-.93-.3-1.4-.11-1.73.7-2.86 4.45-4.56 10.51-1.32 4.71-2.97 10.58-4.72 11.29-.16.07-.39.12-.74-.08-2.36-1.36-2.2-9.52-2.08-16.08.16-8.16.14-13.23-1.99-14.46-.58-.34-1.07-.23-1.38-.08-1.69.8-2.49 5.17-3.61 12.27-.72 4.54-1.7 10.76-2.92 11.34-.04.02-.09.04-.24-.04-1.56-.9-1.59-7.96-1.11-12.69l-1.22-.12c-.2 2.03-1.08 12.25 1.72 13.87.58.34 1.07.23 1.38.08 1.68-.8 2.48-5.17 3.6-12.26.72-4.54 1.7-10.77 2.92-11.35.04-.02.09-.05.24.04 1.61.93 1.48 7.54 1.37 13.37-.15 7.94-.29 15.44 2.69 17.16.6.34 1.22.4 1.82.16 2.3-.94 3.82-6.36 5.44-12.1 1.15-4.09 2.58-9.19 3.84-9.7.09-.04.18-.04.32.04 1.74 1 1.96 8.76 2.13 15 .25 9.12.57 14.75 2.74 16 .5.29 1.04.35 1.56.17 1.75-.58 3.11-3.75 4.55-7.1 1.1-2.55 2.46-5.74 3.56-6.1.15-.05.27-.03.42.05 1.91 1.1 2.22 12.04 1.82 19.46l1.23.08z'
      fill='url(#banner_svg__SVGID_19_)'
    />
    <linearGradient
      id='banner_svg__SVGID_20_'
      gradientUnits='userSpaceOnUse'
      x1={606.106}
      y1={180.781}
      x2={643.403}
      y2={180.781}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M418.19 201.07c.14-2.64.69-15.91-2.11-17.53-.41-.24-.85-.28-1.28-.14-1.41.47-2.47 2.94-3.7 5.8-.97 2.25-2.17 5.06-3.17 5.39-.09.03-.22.05-.39-.05-1.42-.82-1.61-7.36-1.75-12.61-.22-7.77-.48-12.57-2.37-13.66a1.38 1.38 0 00-1.26-.1c-1.51.61-2.47 3.81-3.92 8.96-1.12 3.97-2.51 8.92-3.94 9.51-.13.05-.29.08-.54-.06-1.95-1.12-1.82-8.01-1.71-13.54.13-6.95.12-11.26-1.73-12.33-.41-.24-.86-.27-1.26-.07-1.5.71-2.16 4.44-3.11 10.47-.57 3.61-1.43 9.07-2.42 9.54-.02.01-.03.02-.11-.03-.77-.45-1.56-4.05-.9-10.66l-1.22-.12c-.17 1.73-.92 10.45 1.51 11.85.41.24.86.26 1.26.07 1.5-.72 2.16-4.44 3.11-10.46.57-3.61 1.43-9.07 2.42-9.55.02-.01.04-.02.11.03 1.32.76 1.21 6.58 1.12 11.25-.13 6.76-.25 13.14 2.33 14.63.53.31 1.09.35 1.62.14 1.99-.81 3.29-5.43 4.66-10.31.97-3.45 2.17-7.74 3.2-8.16.04-.02.08-.03.19.03 1.43.82 1.61 7.37 1.76 12.63.22 7.76.48 12.55 2.37 13.64.45.26.93.31 1.4.15 1.52-.5 2.68-3.21 3.91-6.07.92-2.15 2.07-4.82 2.96-5.12.08-.03.16-.03.28.04 1.58.91 1.83 10.14 1.5 16.4l1.18.04z'
      fill='url(#banner_svg__SVGID_20_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_10_'
        filterUnits='userSpaceOnUse'
        x={418.45}
        y={163.91}
        width={4.53}
        height={7.34}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={418.45} y={163.91} width={4.53} height={7.34} id='banner_svg__SVGID_21_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_10_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_11_'
            filterUnits='userSpaceOnUse'
            x={418.45}
            y={163.91}
            width={4.53}
            height={7.34}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_22_'
          gradientUnits='userSpaceOnUse'
          x1={601.457}
          y1={167.583}
          x2={605.99}
          y2={167.583}
          gradientTransform='matrix(-1 0 0 1 1024.435 0)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M422.98 166.63v4.29c0 .26-.28.42-.5.29l-3.69-2.13a.659.659 0 01-.33-.58v-4.23c0-.28.3-.45.54-.31l3.68 2.12c.18.11.3.33.3.55z'
          mask='url(#banner_svg__SVGID_21_)'
          fill='url(#banner_svg__SVGID_22_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_23_'
      gradientUnits='userSpaceOnUse'
      x1={601.457}
      y1={167.583}
      x2={605.99}
      y2={167.583}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M422.98 166.63v4.29c0 .26-.28.42-.5.29l-3.69-2.13a.659.659 0 01-.33-.58v-4.23c0-.28.3-.45.54-.31l3.68 2.12c.18.11.3.33.3.55z'
      fill='url(#banner_svg__SVGID_23_)'
    />
    <linearGradient
      id='banner_svg__SVGID_24_'
      gradientUnits='userSpaceOnUse'
      x1={611.81}
      y1={206.198}
      x2={618.357}
      y2={206.198}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M412.63 206.69l-.01 2.63c0 .29-.32.48-.57.33l-5.59-3.22a.76.76 0 01-.38-.66l.01-2.73c0-.27.29-.44.52-.3l5.6 3.24c.26.14.42.42.42.71z'
      fill='url(#banner_svg__SVGID_24_)'
    />
    <linearGradient
      id='banner_svg__SVGID_25_'
      gradientUnits='userSpaceOnUse'
      x1={628.086}
      y1={196.788}
      x2={634.633}
      y2={196.788}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M396.35 197.28l-.01 2.63c0 .29-.32.48-.57.33l-5.59-3.22a.76.76 0 01-.38-.66l.01-2.73c0-.27.29-.44.52-.3l5.6 3.24c.26.14.42.42.42.71z'
      fill='url(#banner_svg__SVGID_25_)'
    />
    <linearGradient
      id='banner_svg__SVGID_26_'
      gradientUnits='userSpaceOnUse'
      x1={644.355}
      y1={187.43}
      x2={650.902}
      y2={187.43}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M380.08 187.92l-.01 2.63c0 .29-.32.48-.57.33l-5.59-3.22a.76.76 0 01-.38-.66l.01-2.73c0-.27.29-.44.52-.3l5.6 3.24c.26.14.42.42.42.71z'
      fill='url(#banner_svg__SVGID_26_)'
    />
    <path className='banner_svg__st17' d='M416.15 256.27c.08-.05.17-.09.26-.13h-.01l-.25.13z' />
    <linearGradient
      id='banner_svg__SVGID_27_'
      gradientUnits='userSpaceOnUse'
      x1={574.384}
      y1={214.552}
      x2={590.66}
      y2={214.552}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M450.05 211.14l-.01 15.39c0 .93-1 1.51-1.81 1.04l-13.26-7.66c-.74-.43-1.2-1.22-1.2-2.08v-15.18c0-.99 1.07-1.61 1.93-1.11l13.2 7.61c.71.41 1.15 1.17 1.15 1.99z'
      fill='url(#banner_svg__SVGID_27_)'
    />
    <linearGradient
      id='banner_svg__SVGID_28_'
      gradientUnits='userSpaceOnUse'
      x1={574.384}
      y1={241.674}
      x2={590.66}
      y2={241.674}
      gradientTransform='matrix(-1 0 0 1 1024.435 0)'
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M450.05 238.26l-.01 15.39c0 .93-1 1.51-1.81 1.04l-13.26-7.66c-.74-.43-1.2-1.22-1.2-2.08v-15.18c0-.99 1.07-1.61 1.93-1.11l13.2 7.61c.71.42 1.15 1.17 1.15 1.99z'
      fill='url(#banner_svg__SVGID_28_)'
    />
    <linearGradient
      id='banner_svg__SVGID_29_'
      gradientUnits='userSpaceOnUse'
      x1={224.076}
      y1={272.046}
      x2={255.085}
      y2={272.046}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_29_)' d='M224.08 278.5v5.21l31.01-18.12v-5.21z' />
    <path className='banner_svg__st16' d='M192.87 260.48l31.21 18.02 31.01-18.12-31.01-17.92z' />
    <linearGradient
      id='banner_svg__SVGID_30_'
      gradientUnits='userSpaceOnUse'
      x1={906.862}
      y1={258.503}
      x2={922.317}
      y2={258.503}
      gradientTransform='translate(-688.481)'
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_30_)' d='M233.84 257.02l-5.14-2.96-10.32 5.9 5.19 2.99z' />
    <linearGradient
      id='banner_svg__SVGID_31_'
      gradientUnits='userSpaceOnUse'
      x1={908.623}
      y1={265.42}
      x2={931.144}
      y2={265.42}
      gradientTransform='translate(-688.481)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_31_)' d='M237.26 259l5.4 3.12-17.38 9.72-5.14-2.96z' />
    <linearGradient
      id='banner_svg__SVGID_32_'
      gradientUnits='userSpaceOnUse'
      x1={901.777}
      y1={252.559}
      x2={913.759}
      y2={252.559}
      gradientTransform='translate(-688.481)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_32_)' d='M225.28 252.08l-6.9 3.92-5.08-2.93 6.84-3.95z' />
    <linearGradient
      id='banner_svg__SVGID_33_'
      gradientUnits='userSpaceOnUse'
      x1={192.868}
      y1={272.093}
      x2={224.076}
      y2={272.093}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_33_)' d='M192.87 260.48v5.21l31.21 18.02v-5.21z' />
    <linearGradient
      id='banner_svg__SVGID_34_'
      gradientUnits='userSpaceOnUse'
      x1={214.61}
      y1={189.298}
      x2={280.221}
      y2={189.298}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_34_)' d='M280.22 148.77l-.04 118.94-65.57-37.85V110.89l46.84 27.04v21.6z' />
    <linearGradient
      id='banner_svg__SVGID_35_'
      gradientUnits='userSpaceOnUse'
      x1={214.61}
      y1={116.286}
      x2={345.741}
      y2={116.286}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      fill='url(#banner_svg__SVGID_35_)'
      d='M345.74 110.89l-65.52 37.88-18.77 10.76v-21.6l-46.84-27.04 65.57-37.85z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_12_'
        filterUnits='userSpaceOnUse'
        x={191.75}
        y={209.58}
        width={64.44}
        height={37.32}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={191.75} y={209.58} width={64.44} height={37.32} id='banner_svg__SVGID_36_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_12_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_13_'
            filterUnits='userSpaceOnUse'
            x={191.75}
            y={209.58}
            width={64.44}
            height={37.32}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_37_'
          gradientUnits='userSpaceOnUse'
          x1={191.752}
          y1={228.243}
          x2={256.196}
          y2={228.243}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.124} stopColor='#f6f6f6' />
          <stop offset={0.326} stopColor='#ddd' />
          <stop offset={0.582} stopColor='#b6b6b6' />
          <stop offset={0.878} stopColor='#7f7f7f' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M224.08 246.9l-32.32-18.66 32.32-18.66 32.12 18.56-32.12 18.76zm-30.1-18.66l30.09 17.37 29.9-17.46-29.9-17.28-30.09 17.37z'
          mask='url(#banner_svg__SVGID_36_)'
          fill='url(#banner_svg__SVGID_37_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_38_'
      gradientUnits='userSpaceOnUse'
      x1={191.752}
      y1={228.243}
      x2={256.196}
      y2={228.243}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.517} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M224.08 246.9l-32.32-18.66 32.32-18.66 32.12 18.56-32.12 18.76zm-30.1-18.66l30.09 17.37 29.9-17.46-29.9-17.28-30.09 17.37z'
      fill='url(#banner_svg__SVGID_38_)'
    />
    <linearGradient
      id='banner_svg__SVGID_39_'
      gradientUnits='userSpaceOnUse'
      x1={167.779}
      y1={113.572}
      x2={214.61}
      y2={113.572}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_39_)' d='M214.61 100.05v54.07l-42.77-24.69-4.06-2.34V73.02v.01z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_14_'
        filterUnits='userSpaceOnUse'
        x={191.75}
        y={217.42}
        width={64.44}
        height={37.32}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={191.75} y={217.42} width={64.44} height={37.32} id='banner_svg__SVGID_40_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_14_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_15_'
            filterUnits='userSpaceOnUse'
            x={191.75}
            y={217.42}
            width={64.44}
            height={37.32}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_41_'
          gradientUnits='userSpaceOnUse'
          x1={191.752}
          y1={236.077}
          x2={256.196}
          y2={236.077}
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.124} stopColor='#f6f6f6' />
          <stop offset={0.326} stopColor='#ddd' />
          <stop offset={0.582} stopColor='#b6b6b6' />
          <stop offset={0.878} stopColor='#7f7f7f' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M224.08 254.74l-32.32-18.66 32.32-18.66 32.12 18.56-32.12 18.76zm-30.1-18.66l30.09 17.37 29.9-17.46-29.9-17.28-30.09 17.37z'
          mask='url(#banner_svg__SVGID_40_)'
          fill='url(#banner_svg__SVGID_41_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_42_'
      gradientUnits='userSpaceOnUse'
      x1={191.752}
      y1={236.077}
      x2={256.196}
      y2={236.077}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.517} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#fff' />
    </linearGradient>
    <path
      d='M224.08 254.74l-32.32-18.66 32.32-18.66 32.12 18.56-32.12 18.76zm-30.1-18.66l30.09 17.37 29.9-17.46-29.9-17.28-30.09 17.37z'
      fill='url(#banner_svg__SVGID_42_)'
    />
    <path
      className='banner_svg__st16'
      d='M345.74 110.89v118.97l-65.56 37.85.03-75.74-18.77 10.85-37.46 21.61s2.66-70.3-9.37-70.3v-54.07s5.23-3.02 12.21-5.02c13.22-3.8 32.69-3.92 34.49 27.23.03.5.06 1 .07 1.51.03.71.05 1.45.06 2.2.01.37.01.75.01 1.12v32.44l18.78-10.76 65.51-37.89z'
    />
    <linearGradient
      id='banner_svg__SVGID_43_'
      gradientUnits='userSpaceOnUse'
      x1={167.783}
      y1={95.62}
      x2={261.226}
      y2={95.62}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M261.23 121.07c-3.41-45.96-46.62-21.03-46.62-21.03l-46.83-27.02c0 .01 88.57-20.45 93.45 48.05z'
      fill='url(#banner_svg__SVGID_43_)'
    />
    <linearGradient
      id='banner_svg__SVGID_44_'
      gradientUnits='userSpaceOnUse'
      x1={170.392}
      y1={125.578}
      x2={211.996}
      y2={125.578}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_44_)' d='M170.39 125.58L212 101.56v48.04z' />
    <linearGradient
      id='banner_svg__SVGID_45_'
      gradientUnits='userSpaceOnUse'
      x1={170.392}
      y1={101.566}
      x2={211.996}
      y2={101.566}
    >
      <stop offset={0} stopColor='#3f81ff' />
      <stop offset={1} stopColor='#6aaeff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_45_)' d='M170.39 125.58V77.55L212 101.56z' />
    <linearGradient
      id='banner_svg__SVGID_46_'
      gradientUnits='userSpaceOnUse'
      x1={171.838}
      y1={176.93}
      x2={224.232}
      y2={176.93}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M223.98 224.43l-46.83-27.04s2-58.23-5.31-67.95l42.77 24.69c12.03-.01 9.37 70.3 9.37 70.3z'
      fill='url(#banner_svg__SVGID_46_)'
    />
    <linearGradient
      id='banner_svg__SVGID_47_'
      gradientUnits='userSpaceOnUse'
      x1={275.49}
      y1={103.228}
      x2={304.671}
      y2={103.228}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M304.67 103.23c0 .13 0 .26-.02.38a5.25 5.25 0 01-.38 1.66c-.08.21-.18.42-.29.62-1.88 3.54-7.39 6.11-13.91 6.11-6.39 0-11.83-2.47-13.8-5.92-.15-.26-.28-.53-.39-.81-.08-.2-.15-.41-.2-.62a5.3 5.3 0 01-.19-1.43c0-4.85 6.53-8.77 14.59-8.77s14.59 3.93 14.59 8.78z'
      fill='url(#banner_svg__SVGID_47_)'
    />
    <path
      className='banner_svg__st16'
      d='M302.61 73.58v28.55c0 .6-.12 1.2-.34 1.76-1.32 3.31-6.28 5.77-12.19 5.77-5.91 0-10.87-2.46-12.19-5.77-.22-.56-.34-1.15-.34-1.76V73.58c0 .6.12 1.2.34 1.76 1.32 3.31 6.27 5.77 12.19 5.77 5.91 0 10.87-2.46 12.19-5.77.22-.57.34-1.16.34-1.76z'
    />
    <linearGradient
      id='banner_svg__SVGID_48_'
      gradientUnits='userSpaceOnUse'
      x1={277.548}
      y1={70.285}
      x2={302.61}
      y2={70.285}
    >
      <stop offset={0} stopColor='#3f81ff' />
      <stop offset={1} stopColor='#6aaeff' />
    </linearGradient>
    <path
      d='M302.61 73.58c0 .32-.03.64-.1.95-.78-3.71-6.05-6.58-12.43-6.58s-11.65 2.87-12.43 6.58c-.07-.31-.1-.63-.1-.95 0-4.16 5.61-7.53 12.53-7.53 6.92-.01 12.53 3.36 12.53 7.53z'
      fill='url(#banner_svg__SVGID_48_)'
    />
    <linearGradient
      id='banner_svg__SVGID_49_'
      gradientUnits='userSpaceOnUse'
      x1={277.649}
      y1={74.528}
      x2={302.509}
      y2={74.528}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M302.51 74.53c-.05.28-.14.54-.24.81-1.32 3.31-6.28 5.77-12.19 5.77-5.91 0-10.87-2.46-12.19-5.77-.1-.27-.19-.53-.24-.81.78-3.71 6.05-6.58 12.43-6.58s11.65 2.86 12.43 6.58z'
      fill='url(#banner_svg__SVGID_49_)'
    />
    <linearGradient
      id='banner_svg__SVGID_50_'
      gradientUnits='userSpaceOnUse'
      x1={275.49}
      y1={109.041}
      x2={304.671}
      y2={109.041}
    >
      <stop offset={0} stopColor='#3f81ff' />
      <stop offset={1} stopColor='#6aaeff' />
    </linearGradient>
    <path
      d='M304.67 103.61v2.47c0 .71-.14 1.39-.4 2.05-1.54 3.86-7.31 6.72-14.19 6.72s-12.65-2.86-14.19-6.72c-.26-.65-.4-1.34-.4-2.05 0-.09 0-.17.01-.26l-.01-2.59c0 .49.06.96.19 1.43.06.21.12.42.2.62.11.28.24.54.39.81 1.97 3.44 7.41 5.92 13.8 5.92 6.51 0 12.03-2.56 13.91-6.11.11-.2.2-.41.29-.62a5.25 5.25 0 00.38-1.66h.02z'
      fill='url(#banner_svg__SVGID_50_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_16_'
        filterUnits='userSpaceOnUse'
        x={289.78}
        y={16.98}
        width={59}
        height={57.57}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={289.78} y={16.98} width={59} height={57.57} id='banner_svg__SVGID_51_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_16_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_17_'
            filterUnits='userSpaceOnUse'
            x={289.78}
            y={16.98}
            width={59}
            height={57.57}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_52_'
          gradientUnits='userSpaceOnUse'
          x1={286.839}
          y1={50.907}
          x2={352.452}
          y2={40.764}
        >
          <stop offset={0} stopColor='#ccc' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M348.77 16.98c-4.02 3.14-7.1 8.27-8.41 13.15-.05.18-.09.37-.13.56-.09.02-.2.04-.29.06-.84.16-1.7.3-2.44.71-1.57.85-1.92 3.01-2.37 4.58-.84 2.98-2.81 4.68-5.97 5.23-2.51.44-5.08.08-7.62.05-9.48-.13-10.77 7.56-12.39 15.16-1.12 5.24-8.27 5.59-12.07 7.88-3.76 2.27-5.26 6.08-5.62 10.19-.62-.11-.97-.18-.97-.18-1.21-6.76-1.59-15.51 5.02-19.5 3.79-2.29 10.95-2.64 12.07-7.88 1.62-7.6 2.91-15.29 12.39-15.16 2.54.03 5.11.39 7.62-.05 3.16-.55 5.13-2.25 5.97-5.23.44-1.57.79-3.73 2.37-4.58.75-.4 1.61-.55 2.44-.71 3.82-.71 7.31-1.87 10.4-4.28z'
          mask='url(#banner_svg__SVGID_51_)'
          fill='url(#banner_svg__SVGID_52_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_53_'
      gradientUnits='userSpaceOnUse'
      x1={286.839}
      y1={50.907}
      x2={352.452}
      y2={40.764}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M348.77 16.98c-4.02 3.14-7.1 8.27-8.41 13.15-.05.18-.09.37-.13.56-.09.02-.2.04-.29.06-.84.16-1.7.3-2.44.71-1.57.85-1.92 3.01-2.37 4.58-.84 2.98-2.81 4.68-5.97 5.23-2.51.44-5.08.08-7.62.05-9.48-.13-10.77 7.56-12.39 15.16-1.12 5.24-8.27 5.59-12.07 7.88-3.76 2.27-5.26 6.08-5.62 10.19-.62-.11-.97-.18-.97-.18-1.21-6.76-1.59-15.51 5.02-19.5 3.79-2.29 10.95-2.64 12.07-7.88 1.62-7.6 2.91-15.29 12.39-15.16 2.54.03 5.11.39 7.62-.05 3.16-.55 5.13-2.25 5.97-5.23.44-1.57.79-3.73 2.37-4.58.75-.4 1.61-.55 2.44-.71 3.82-.71 7.31-1.87 10.4-4.28z'
      fill='url(#banner_svg__SVGID_53_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_18_'
        filterUnits='userSpaceOnUse'
        x={291.45}
        y={30.69}
        width={48.78}
        height={44.8}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={291.45} y={30.69} width={48.78} height={44.8} id='banner_svg__SVGID_54_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_18_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_19_'
            filterUnits='userSpaceOnUse'
            x={291.45}
            y={30.69}
            width={48.78}
            height={44.8}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_55_'
          gradientUnits='userSpaceOnUse'
          x1={291.452}
          y1={53.09}
          x2={340.228}
          y2={53.09}
        >
          <stop offset={0} stopColor='#ccc' />
          <stop offset={0.326} stopColor='#e2e2e2' />
          <stop offset={0.734} stopColor='#f7f7f7' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M340.23 30.69c-.84 3.85.08 8.02-1.53 11.7-1.65 3.79-5.62 6.19-9.34 7.55-2.8 1.03-5.6 2.04-8.34 3.2-3.31 1.4-5.96 3.15-6.96 6.78-.42 1.51-.58 3.08-.95 4.6-1.25 5.19-7.39 11.22-12.98 10.95-3.91-.19-7.14-.67-8.68-.93.37-4.11 1.86-7.92 5.62-10.19 3.8-2.29 10.96-2.64 12.07-7.88 1.62-7.59 2.91-15.29 12.39-15.16 2.54.03 5.11.38 7.62-.05 3.16-.56 5.13-2.25 5.97-5.23.44-1.57.8-3.73 2.37-4.58.75-.41 1.61-.55 2.44-.71.1-.01.2-.03.3-.05z'
          mask='url(#banner_svg__SVGID_54_)'
          fill='url(#banner_svg__SVGID_55_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_56_'
      gradientUnits='userSpaceOnUse'
      x1={291.452}
      y1={53.09}
      x2={340.228}
      y2={53.09}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M340.23 30.69c-.84 3.85.08 8.02-1.53 11.7-1.65 3.79-5.62 6.19-9.34 7.55-2.8 1.03-5.6 2.04-8.34 3.2-3.31 1.4-5.96 3.15-6.96 6.78-.42 1.51-.58 3.08-.95 4.6-1.25 5.19-7.39 11.22-12.98 10.95-3.91-.19-7.14-.67-8.68-.93.37-4.11 1.86-7.92 5.62-10.19 3.8-2.29 10.96-2.64 12.07-7.88 1.62-7.59 2.91-15.29 12.39-15.16 2.54.03 5.11.38 7.62-.05 3.16-.56 5.13-2.25 5.97-5.23.44-1.57.8-3.73 2.37-4.58.75-.41 1.61-.55 2.44-.71.1-.01.2-.03.3-.05z'
      fill='url(#banner_svg__SVGID_56_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_20_'
        filterUnits='userSpaceOnUse'
        x={319.21}
        y={51.48}
        width={35.33}
        height={17.58}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={319.21} y={51.48} width={35.33} height={17.58} id='banner_svg__SVGID_57_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_20_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_21_'
            filterUnits='userSpaceOnUse'
            x={319.21}
            y={51.48}
            width={35.33}
            height={17.58}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_58_'
          gradientUnits='userSpaceOnUse'
          x1={319.21}
          y1={60.271}
          x2={354.543}
          y2={60.271}
        >
          <stop offset={0} stopColor='#ccc' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M354.54 51.48c-2.26 1.32-4.1 2.93-5.48 4.98-.5-.08-.99-.11-1.43.08-1.32.56-1.42 2.55-2.69 3.03-3.21 1.23-5.66-1.2-8.21-1.32-1.81-.09-3.19 1.07-4.21 2.45-1 1.36-1.52 3.39-3 4.32-2.14 1.34-4.69.55-6.97 1.34-1.26.44-2.24 1.47-2.81 2.68a5.55 5.55 0 01-.46-3.25c.28-1.8 1.52-3.57 3.27-4.18 2.28-.79 4.83 0 6.97-1.34 1.47-.92 2-2.96 3-4.32 1.02-1.38 2.39-2.54 4.21-2.45 2.55.12 5 2.56 8.21 1.32 1.27-.49 1.37-2.48 2.69-3.03.9-.39 1.98.11 2.92.17 1.39.1 2.68-.24 3.99-.48z'
          mask='url(#banner_svg__SVGID_57_)'
          fill='url(#banner_svg__SVGID_58_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_59_'
      gradientUnits='userSpaceOnUse'
      x1={319.21}
      y1={60.271}
      x2={354.543}
      y2={60.271}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M354.54 51.48c-2.26 1.32-4.1 2.93-5.48 4.98-.5-.08-.99-.11-1.43.08-1.32.56-1.42 2.55-2.69 3.03-3.21 1.23-5.66-1.2-8.21-1.32-1.81-.09-3.19 1.07-4.21 2.45-1 1.36-1.52 3.39-3 4.32-2.14 1.34-4.69.55-6.97 1.34-1.26.44-2.24 1.47-2.81 2.68a5.55 5.55 0 01-.46-3.25c.28-1.8 1.52-3.57 3.27-4.18 2.28-.79 4.83 0 6.97-1.34 1.47-.92 2-2.96 3-4.32 1.02-1.38 2.39-2.54 4.21-2.45 2.55.12 5 2.56 8.21 1.32 1.27-.49 1.37-2.48 2.69-3.03.9-.39 1.98.11 2.92.17 1.39.1 2.68-.24 3.99-.48z'
      fill='url(#banner_svg__SVGID_59_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_22_'
        filterUnits='userSpaceOnUse'
        x={319.74}
        y={56.4}
        width={29.32}
        height={15.6}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={319.74} y={56.4} width={29.32} height={15.6} id='banner_svg__SVGID_60_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_22_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_23_'
            filterUnits='userSpaceOnUse'
            x={319.74}
            y={56.4}
            width={29.32}
            height={15.6}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_61_'
          gradientUnits='userSpaceOnUse'
          x1={319.742}
          y1={64.2}
          x2={349.059}
          y2={64.2}
        >
          <stop offset={0} stopColor='#ccc' />
          <stop offset={0.083} stopColor='#d1d1d1' />
          <stop offset={0.676} stopColor='#f2f2f2' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M349.06 56.46c-.58.85-1.07 1.78-1.49 2.79-1.29 3.16-3.73 4.67-7.13 4.8-1.65.07-3.33.1-4.97.27-1.37.14-2.67.51-3.54 1.64-.55.73-.92 1.57-1.42 2.34-1.55 2.38-5.01 4.49-7.91 3.4a.689.689 0 01-.08-.03c-1.27-.49-2.23-1.44-2.79-2.62.56-1.22 1.55-2.25 2.81-2.68 2.28-.79 4.83 0 6.97-1.34 1.47-.93 2-2.96 3-4.32 1.02-1.38 2.39-2.54 4.21-2.45 2.55.12 5 2.56 8.21 1.32 1.27-.49 1.37-2.47 2.69-3.03.45-.2.94-.17 1.44-.09z'
          mask='url(#banner_svg__SVGID_60_)'
          fill='url(#banner_svg__SVGID_61_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_62_'
      gradientUnits='userSpaceOnUse'
      x1={319.742}
      y1={64.2}
      x2={349.059}
      y2={64.2}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M349.06 56.46c-.58.85-1.07 1.78-1.49 2.79-1.29 3.16-3.73 4.67-7.13 4.8-1.65.07-3.33.1-4.97.27-1.37.14-2.67.51-3.54 1.64-.55.73-.92 1.57-1.42 2.34-1.55 2.38-5.01 4.49-7.91 3.4a.689.689 0 01-.08-.03c-1.27-.49-2.23-1.44-2.79-2.62.56-1.22 1.55-2.25 2.81-2.68 2.28-.79 4.83 0 6.97-1.34 1.47-.93 2-2.96 3-4.32 1.02-1.38 2.39-2.54 4.21-2.45 2.55.12 5 2.56 8.21 1.32 1.27-.49 1.37-2.47 2.69-3.03.45-.2.94-.17 1.44-.09z'
      fill='url(#banner_svg__SVGID_62_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_24_'
        filterUnits='userSpaceOnUse'
        x={226.78}
        y={93.3}
        width={118.96}
        height={136.56}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={226.78} y={93.3} width={118.96} height={136.56} id='banner_svg__SVGID_63_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_24_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_25_'
            filterUnits='userSpaceOnUse'
            x={226.78}
            y={93.3}
            width={118.96}
            height={136.56}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_64_'
          gradientUnits='userSpaceOnUse'
          x1={226.781}
          y1={161.578}
          x2={345.74}
          y2={161.578}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.122} stopColor='#7f7f7f' />
          <stop offset={0.418} stopColor='#b6b6b6' />
          <stop offset={0.674} stopColor='#ddd' />
          <stop offset={0.876} stopColor='#f6f6f6' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M345.74 110.89v118.97s-36.54-47.35-81.77-57.52c-39.74-8.94-37.16-77.31-37.16-77.31 13.22-3.8 32.69-3.92 34.49 27.23.03.5.06 1 .07 1.51.03.71.05 1.45.06 2.2.01.37.01.75.01 1.12v32.44l18.78-10.76 65.52-37.88z'
          mask='url(#banner_svg__SVGID_63_)'
          fill='url(#banner_svg__SVGID_64_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_65_'
      gradientUnits='userSpaceOnUse'
      x1={226.781}
      y1={161.578}
      x2={345.74}
      y2={161.578}
    >
      <stop offset={0} stopColor='#fff' />
      <stop offset={0.483} stopColor='#f2f6ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M345.74 110.89v118.97s-36.54-47.35-81.77-57.52c-39.74-8.94-37.16-77.31-37.16-77.31 13.22-3.8 32.69-3.92 34.49 27.23.03.5.06 1 .07 1.51.03.71.05 1.45.06 2.2.01.37.01.75.01 1.12v32.44l18.78-10.76 65.52-37.88z'
      fill='url(#banner_svg__SVGID_65_)'
    />
    <linearGradient
      id='banner_svg__SVGID_66_'
      gradientUnits='userSpaceOnUse'
      x1={295.913}
      y1={183.441}
      x2={336.853}
      y2={183.441}
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#00276a' />
    </linearGradient>
    <path
      d='M336.85 158.38c-1.26-.43-2.65-.62-4.12-.53-.7.04-1.43.14-2.17.3-1.69.37-3.47 1.06-5.32 2.1-11.16 6.29-20.43 22.86-20.69 37.01-.06 3.1.32 5.83 1.07 8.14.61 1.9 1.47 3.5 2.55 4.79.9 1.09 1.96 1.96 3.13 2.57l-7.53-3.21-.77-.33c-4.46-2.13-7.23-7.65-7.08-15.67.27-14.15 9.54-30.72 20.7-37.01 4.6-2.59 8.81-3.03 12.14-1.66l.47.2 7.62 3.3z'
      fill='url(#banner_svg__SVGID_66_)'
    />
    <linearGradient
      id='banner_svg__SVGID_67_'
      gradientUnits='userSpaceOnUse'
      x1={304.538}
      y1={183.936}
      x2={324.282}
      y2={183.936}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M314.35 191.73c-.01.66.01 1.28.07 1.86l-9.54 8.74c-.26-1.56-.37-3.26-.34-5.09.21-11.14 6-23.78 13.9-31.72l5.84 7.58c-5.42 3.53-9.8 11.65-9.93 18.63z'
      fill='url(#banner_svg__SVGID_67_)'
    />
    <linearGradient
      id='banner_svg__SVGID_68_'
      gradientUnits='userSpaceOnUse'
      x1={304.884}
      y1={199.772}
      x2={340.25}
      y2={199.772}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M340.25 192.94c-3.81 7.96-9.58 14.94-15.98 18.54-4.98 2.8-9.49 3.09-12.94 1.3l-.04-.02c-1.18-.62-2.23-1.48-3.13-2.57-1.07-1.29-1.93-2.89-2.55-4.79-.31-.94-.55-1.96-.73-3.05l6.27-5.75 1.31-1.2 1.95-1.79c.09.83.24 1.59.46 2.28 0 .02.01.03.01.05.32.98.76 1.8 1.31 2.47.47.56 1.01 1.01 1.62 1.33l.02.01c1.78.93 4.1.78 6.67-.67.72-.41 1.43-.9 2.11-1.46 3.39-2.79 6.23-7.3 7.63-11.97l6.01 7.29z'
      fill='url(#banner_svg__SVGID_68_)'
    />
    <linearGradient
      id='banner_svg__SVGID_69_'
      gradientUnits='userSpaceOnUse'
      x1={318.444}
      y1={165.56}
      x2={340.654}
      y2={165.56}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M340.65 160.77l-2.54 4.32-4.82 8.2c-.46-.55-1-.99-1.6-1.3l-.7-.3c-.64-.22-1.34-.31-2.09-.28h-.03c-.37.02-.74.07-1.12.16-.87.19-1.79.54-2.74 1.08-.24.14-.49.29-.73.44l-5.84-7.58c2.13-2.15 4.42-3.95 6.79-5.29 1.85-1.04 3.63-1.73 5.32-2.1.74-.17 1.47-.27 2.17-.3 1.48-.08 2.86.1 4.12.53l1.35.58c.9.5 1.73 1.1 2.46 1.84z'
      fill='url(#banner_svg__SVGID_69_)'
    />
    <linearGradient
      id='banner_svg__SVGID_70_'
      gradientUnits='userSpaceOnUse'
      x1={314.88}
      y1={191.129}
      x2={334.248}
      y2={191.129}
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#00276a' />
    </linearGradient>
    <path
      d='M334.25 185.64c-1.4 4.67-4.23 9.18-7.63 11.97-.68.56-1.39 1.05-2.11 1.46-2.56 1.44-4.89 1.59-6.67.67l-.02-.01c-.61-.32-1.15-.76-1.62-1.33-.55-.67-1-1.49-1.31-2.47 0-.02-.01-.03-.01-.05.33-.14.67-.31 1-.5 4.3-2.43 8.06-7.81 9.74-13.43l8.63 3.69z'
      fill='url(#banner_svg__SVGID_70_)'
    />
    <linearGradient
      id='banner_svg__SVGID_71_'
      gradientUnits='userSpaceOnUse'
      x1={328.902}
      y1={172.358}
      x2={333.287}
      y2={172.358}
    >
      <stop offset={0} stopColor='#004daa' />
      <stop offset={1} stopColor='#00276a' />
    </linearGradient>
    <path
      d='M333.29 173.3l-4.39-1.88c.75-.03 1.45.06 2.09.28l.7.3c.6.31 1.14.75 1.6 1.3z'
      fill='url(#banner_svg__SVGID_71_)'
    />
    <linearGradient
      id='banner_svg__SVGID_72_'
      gradientUnits='userSpaceOnUse'
      x1={336.921}
      y1={109.769}
      x2={341.21}
      y2={109.769}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M341.21 107.05v3.98c0 .8-.96 1.46-2.14 1.46s-2.15-.65-2.15-1.46v-3.98c0 .8.96 1.46 2.15 1.46s2.14-.66 2.14-1.46z'
      fill='url(#banner_svg__SVGID_72_)'
    />
    <linearGradient
      id='banner_svg__SVGID_73_'
      gradientUnits='userSpaceOnUse'
      x1={336.921}
      y1={107.05}
      x2={341.21}
      y2={107.05}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M341.21 107.05c0 .8-.96 1.46-2.14 1.46s-2.15-.65-2.15-1.46c0-.8.96-1.46 2.15-1.46s2.14.66 2.14 1.46z'
      fill='url(#banner_svg__SVGID_73_)'
    />
    <linearGradient
      id='banner_svg__SVGID_74_'
      gradientUnits='userSpaceOnUse'
      x1={264.815}
      y1={150.325}
      x2={269.103}
      y2={150.325}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M269.1 147.61v3.98c0 .8-.96 1.46-2.14 1.46-1.18 0-2.15-.65-2.15-1.46v-3.98c0 .8.96 1.46 2.15 1.46 1.18-.01 2.14-.66 2.14-1.46z'
      fill='url(#banner_svg__SVGID_74_)'
    />
    <linearGradient
      id='banner_svg__SVGID_75_'
      gradientUnits='userSpaceOnUse'
      x1={264.815}
      y1={147.607}
      x2={269.103}
      y2={147.607}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M269.1 147.61c0 .8-.96 1.46-2.14 1.46-1.18 0-2.15-.65-2.15-1.46s.96-1.46 2.15-1.46c1.18 0 2.14.65 2.14 1.46z'
      fill='url(#banner_svg__SVGID_75_)'
    />
    <linearGradient
      id='banner_svg__SVGID_76_'
      gradientUnits='userSpaceOnUse'
      x1={300.465}
      y1={130.162}
      x2={304.754}
      y2={130.162}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M304.75 127.44v3.98c0 .8-.96 1.46-2.14 1.46s-2.15-.65-2.15-1.46v-3.98c0 .8.96 1.46 2.15 1.46s2.14-.65 2.14-1.46z'
      fill='url(#banner_svg__SVGID_76_)'
    />
    <linearGradient
      id='banner_svg__SVGID_77_'
      gradientUnits='userSpaceOnUse'
      x1={300.465}
      y1={127.444}
      x2={304.754}
      y2={127.444}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M304.75 127.44c0 .8-.96 1.46-2.14 1.46s-2.15-.65-2.15-1.46c0-.8.96-1.46 2.15-1.46s2.14.66 2.14 1.46z'
      fill='url(#banner_svg__SVGID_77_)'
    />
    <linearGradient
      id='banner_svg__SVGID_78_'
      gradientUnits='userSpaceOnUse'
      x1={226.515}
      y1={130.162}
      x2={245.007}
      y2={130.162}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M244.88 128.4c-.28 2.05-1.05 4.19-2.27 6.15-.56.92-1.23 1.79-1.98 2.6-3.33 3.55-7.45 4.75-10.4 3.39-.02-.01-.05-.02-.07-.04l-.1-.04a5.67 5.67 0 01-1.72-1.35c-.15-.17-.3-.36-.43-.56-2.68-3.86-1.34-10.75 3.01-15.4 2.55-2.73 5.57-4.07 8.17-3.92.45.02.88.08 1.3.2.32.09.63.2.94.33l.16.08c.83.42 1.57 1.05 2.15 1.9 1.21 1.77 1.59 4.15 1.24 6.66z'
      fill='url(#banner_svg__SVGID_78_)'
    />
    <linearGradient
      id='banner_svg__SVGID_79_'
      gradientUnits='userSpaceOnUse'
      x1={238.788}
      y1={134.893}
      x2={249.276}
      y2={134.893}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M249.21 133.9c-.16 1.16-.6 2.38-1.29 3.49-.32.52-.7 1.02-1.12 1.48-1.89 2.01-4.23 2.7-5.9 1.92-.01-.01-.03-.01-.04-.02l-.06-.02a3.058 3.058 0 01-.98-.76c-.08-.1-.17-.2-.25-.32-1.52-2.19-.76-6.1 1.71-8.73 1.44-1.55 3.16-2.31 4.63-2.23a3.348 3.348 0 011.27.3l.09.05c.47.24.89.59 1.22 1.08.7.98.91 2.33.72 3.76z'
      fill='url(#banner_svg__SVGID_79_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_26_'
        filterUnits='userSpaceOnUse'
        x={344.54}
        y={26.35}
        width={20.61}
        height={17.7}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={344.54} y={26.35} width={20.61} height={17.7} id='banner_svg__SVGID_80_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_26_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_27_'
            filterUnits='userSpaceOnUse'
            x={344.54}
            y={26.35}
            width={20.61}
            height={17.7}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_81_'
          gradientUnits='userSpaceOnUse'
          x1={330.748}
          y1={-0.032}
          x2={354.756}
          y2={-0.032}
          gradientTransform='rotate(-15.138 481.71 -28.944)'
        >
          <stop offset={0} stopColor='#ccc' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M365.15 26.35c-1.25 1.27-2.17 2.65-2.71 4.24-.34.03-.67.1-.93.31-.76.6-.48 1.92-1.23 2.47-1.89 1.38-3.93.22-5.62.59-1.21.26-1.9 1.27-2.32 2.36-.41 1.07-.4 2.49-1.2 3.36-1.17 1.26-2.98 1.19-4.34 2.12-.75.51-1.21 1.36-1.36 2.26-.48-.56-.8-1.28-.88-2.05-.13-1.23.37-2.61 1.4-3.32 1.36-.92 3.17-.86 4.34-2.12.8-.87.79-2.29 1.2-3.36.42-1.09 1.12-2.09 2.32-2.35 1.69-.37 3.74.79 5.62-.59.75-.54.46-1.87 1.23-2.47.52-.42 1.32-.28 1.94-.41.93-.2 1.72-.65 2.54-1.04z'
          mask='url(#banner_svg__SVGID_80_)'
          fill='url(#banner_svg__SVGID_81_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_82_'
      gradientUnits='userSpaceOnUse'
      x1={330.748}
      y1={-0.032}
      x2={354.756}
      y2={-0.032}
      gradientTransform='rotate(-15.138 481.71 -28.944)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M365.15 26.35c-1.25 1.27-2.17 2.65-2.71 4.24-.34.03-.67.1-.93.31-.76.6-.48 1.92-1.23 2.47-1.89 1.38-3.93.22-5.62.59-1.21.26-1.9 1.27-2.32 2.36-.41 1.07-.4 2.49-1.2 3.36-1.17 1.26-2.98 1.19-4.34 2.12-.75.51-1.21 1.36-1.36 2.26-.48-.56-.8-1.28-.88-2.05-.13-1.23.37-2.61 1.4-3.32 1.36-.92 3.17-.86 4.34-2.12.8-.87.79-2.29 1.2-3.36.42-1.09 1.12-2.09 2.32-2.35 1.69-.37 3.74.79 5.62-.59.75-.54.46-1.87 1.23-2.47.52-.42 1.32-.28 1.94-.41.93-.2 1.72-.65 2.54-1.04z'
      fill='url(#banner_svg__SVGID_82_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_28_'
        filterUnits='userSpaceOnUse'
        x={345.44}
        y={30.59}
        width={16.99}
        height={14.7}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={345.44} y={30.59} width={16.99} height={14.7} id='banner_svg__SVGID_83_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_28_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_29_'
            filterUnits='userSpaceOnUse'
            x={345.44}
            y={30.59}
            width={16.99}
            height={14.7}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_84_'
          gradientUnits='userSpaceOnUse'
          x1={331.11}
          y1={2.639}
          x2={351.029}
          y2={2.639}
          gradientTransform='rotate(-15.138 481.71 -28.944)'
        >
          <stop offset={0} stopColor='#ccc' />
          <stop offset={0.083} stopColor='#d1d1d1' />
          <stop offset={0.676} stopColor='#f2f2f2' />
          <stop offset={1} stopColor='#fff' />
        </linearGradient>
        <path
          d='M362.44 30.59c-.23.66-.39 1.36-.48 2.1-.29 2.3-1.62 3.72-3.83 4.41-1.07.34-2.17.66-3.21 1.06-.87.33-1.66.81-2.03 1.7-.23.57-.32 1.2-.51 1.78-.59 1.84-2.49 3.83-4.58 3.63h-.06c-.92-.09-1.72-.55-2.29-1.22.15-.9.62-1.75 1.36-2.26 1.36-.92 3.17-.86 4.34-2.12.8-.87.78-2.3 1.2-3.36.42-1.09 1.12-2.09 2.32-2.36 1.69-.37 3.74.79 5.62-.59.75-.54.46-1.86 1.23-2.47.25-.2.58-.27.92-.3z'
          mask='url(#banner_svg__SVGID_83_)'
          fill='url(#banner_svg__SVGID_84_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_85_'
      gradientUnits='userSpaceOnUse'
      x1={331.11}
      y1={2.639}
      x2={351.029}
      y2={2.639}
      gradientTransform='rotate(-15.138 481.71 -28.944)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path
      d='M362.44 30.59c-.23.66-.39 1.36-.48 2.1-.29 2.3-1.62 3.72-3.83 4.41-1.07.34-2.17.66-3.21 1.06-.87.33-1.66.81-2.03 1.7-.23.57-.32 1.2-.51 1.78-.59 1.84-2.49 3.83-4.58 3.63h-.06c-.92-.09-1.72-.55-2.29-1.22.15-.9.62-1.75 1.36-2.26 1.36-.92 3.17-.86 4.34-2.12.8-.87.78-2.3 1.2-3.36.42-1.09 1.12-2.09 2.32-2.36 1.69-.37 3.74.79 5.62-.59.75-.54.46-1.86 1.23-2.47.25-.2.58-.27.92-.3z'
      fill='url(#banner_svg__SVGID_85_)'
    />
    <linearGradient
      id='banner_svg__SVGID_86_'
      gradientUnits='userSpaceOnUse'
      x1={138.274}
      y1={124.734}
      x2={157.603}
      y2={124.734}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M157.6 120.73c-.02-.63-.09-1.23-.21-1.81-.22-1.1-.63-2.11-1.23-2.98a5.968 5.968 0 00-2.25-1.98l-.17-.09a6.323 6.323 0 00-2.34-.56c-2.72-.15-5.87 1.25-8.54 4.1-4.55 4.86-5.95 12.06-3.15 16.1.14.21.3.4.45.58.26.29.52.54.81.77.31.25.65.46 1 .64l.1.05c.02.01.05.02.08.03 1.45.67 3.16.75 4.93.26.64-.17 1.28-.42 1.92-.74.5-.24 1-.54 1.49-.88.82-.56 1.62-1.24 2.38-2.04l.14-.14c.79-.85 1.48-1.76 2.08-2.72.79-1.27 1.4-2.61 1.82-3.96.26-.82.44-1.65.55-2.47.05-.37.09-.75.11-1.11.03-.35.04-.7.03-1.05zm-12.5 10.03c-.2-.1-.4-.23-.58-.37a3.31 3.31 0 01-.47-.45c-.09-.11-.18-.22-.26-.34-1.63-2.35-.81-6.54 1.83-9.36 1.55-1.66 3.39-2.47 4.96-2.38.27.01.53.05.79.12.2.05.39.12.57.2l.1.05c.13.06.25.14.37.21l2.42 1.56c-1.16.67-2.31 1.57-3.37 2.71-2.5 2.68-4.06 6.07-4.47 9.25l-1.89-1.2z'
      fill='url(#banner_svg__SVGID_86_)'
    />
    <linearGradient
      id='banner_svg__SVGID_87_'
      gradientUnits='userSpaceOnUse'
      x1={136.137}
      y1={123.871}
      x2={153.74}
      y2={123.871}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M153.74 113.88a6.323 6.323 0 00-2.34-.56c-2.72-.15-5.87 1.25-8.54 4.1-4.55 4.86-5.95 12.06-3.15 16.1.14.21.3.4.45.58.26.29.52.54.81.77.31.25.65.46 1 .64l-2.14-1.07-.06-.03c-.01-.01-.02-.02-.03-.02-.84-.43-1.57-1.08-2.17-1.94-2.8-4.03-1.4-11.24 3.15-16.09 3.49-3.73 7.82-4.98 10.9-3.53l.29.14 1.83.91z'
      fill='url(#banner_svg__SVGID_87_)'
    />
    <linearGradient
      id='banner_svg__SVGID_88_'
      gradientUnits='userSpaceOnUse'
      x1={2.661}
      y1={202.866}
      x2={21.991}
      y2={202.866}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M21.99 198.87c-.02-.63-.09-1.23-.21-1.81-.22-1.1-.63-2.11-1.23-2.98a5.968 5.968 0 00-2.25-1.98l-.17-.09a6.323 6.323 0 00-2.34-.56c-2.72-.15-5.87 1.25-8.54 4.1-4.55 4.86-5.95 12.06-3.15 16.1.14.21.3.4.45.58.26.29.52.54.81.77.31.25.65.46 1 .64l.1.05c.02.01.05.02.08.03 1.45.67 3.16.75 4.93.26.64-.17 1.28-.42 1.92-.74.5-.24 1-.54 1.49-.88.82-.56 1.62-1.24 2.38-2.04l.14-.14c.79-.85 1.48-1.76 2.08-2.72.79-1.27 1.4-2.61 1.82-3.96.26-.82.44-1.65.55-2.47.05-.37.09-.75.11-1.11.03-.36.04-.71.03-1.05zm-12.5 10.02c-.2-.1-.4-.23-.58-.37a3.31 3.31 0 01-.47-.45c-.09-.11-.18-.22-.26-.34-1.63-2.35-.81-6.54 1.83-9.36 1.55-1.66 3.39-2.47 4.96-2.38.27.01.53.05.79.12.2.05.39.12.57.2l.1.05c.13.06.25.14.37.21l2.42 1.56c-1.16.67-2.31 1.57-3.37 2.71-2.5 2.68-4.06 6.07-4.47 9.25l-1.89-1.2z'
      fill='url(#banner_svg__SVGID_88_)'
    />
    <linearGradient
      id='banner_svg__SVGID_89_'
      gradientUnits='userSpaceOnUse'
      x1={0.525}
      y1={202.003}
      x2={18.127}
      y2={202.003}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M18.13 192.01a6.323 6.323 0 00-2.34-.56c-2.72-.15-5.87 1.25-8.54 4.1-4.55 4.86-5.95 12.06-3.15 16.1.14.21.3.4.45.58.26.29.52.54.81.77.31.25.65.46 1 .64l-2.14-1.07-.06-.03c-.01-.01-.02-.02-.03-.02-.84-.43-1.57-1.08-2.17-1.94-2.8-4.03-1.4-11.24 3.15-16.09 3.49-3.73 7.82-4.98 10.9-3.53l.29.14 1.83.91z'
      fill='url(#banner_svg__SVGID_89_)'
    />
    <linearGradient
      id='banner_svg__SVGID_90_'
      gradientUnits='userSpaceOnUse'
      x1={7.331}
      y1={203.046}
      x2={19.213}
      y2={203.046}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M19.21 198.14c-1.16.67-2.31 1.57-3.37 2.71-2.5 2.68-4.06 6.07-4.47 9.25l-1.88-1.21c-.2-.1-.4-.23-.58-.37a3.31 3.31 0 01-.47-.45c-.09-.11-.18-.22-.26-.34-1.63-2.35-.81-6.54 1.83-9.36 1.55-1.66 3.39-2.47 4.96-2.38.27.01.53.05.79.12.2.05.39.12.57.2l.1.05c.13.06.25.14.37.21l2.41 1.57z'
      fill='url(#banner_svg__SVGID_90_)'
    />
    <linearGradient
      id='banner_svg__SVGID_91_'
      gradientUnits='userSpaceOnUse'
      x1={142.944}
      y1={124.914}
      x2={154.826}
      y2={124.914}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M154.83 120.01c-1.16.67-2.31 1.57-3.37 2.71-2.5 2.68-4.06 6.07-4.47 9.25l-1.88-1.21c-.2-.1-.4-.23-.58-.37a3.31 3.31 0 01-.47-.45c-.09-.11-.18-.22-.26-.34-1.63-2.35-.81-6.54 1.83-9.36 1.55-1.66 3.39-2.47 4.96-2.38.27.01.53.05.79.12.2.05.39.12.57.2l.1.05c.13.06.25.14.37.21l2.41 1.57z'
      fill='url(#banner_svg__SVGID_91_)'
    />
    <linearGradient
      id='banner_svg__SVGID_92_'
      gradientUnits='userSpaceOnUse'
      x1={8.907}
      y1={165.12}
      x2={158.379}
      y2={165.12}
    >
      <stop offset={0} stopColor='#aac9ff' />
      <stop offset={1} stopColor='#e9f1ff' />
    </linearGradient>
    <path
      d='M17.57 212.23c-4.22 0-8.46-3.58-8.66-3.71 0-.28-.04-6.64 8.6-11.87 1.26-.76 126.4-76.45 133.95-78.64l.58.23 3.43 1.29 2.92 9.36-.67 3.43-.29.17c-5.43 3.2-133.11 78.39-137.76 79.5-.7.17-1.4.24-2.1.24zm-7.3-3.75c1.27.72 5.48 2.89 9.04 2.05 3.7-.89 99.89-57.27 137.05-79.16l.47-2.4-2.64-8.48-1.89-1.21c-9.31 3.58-119.27 69.71-134.02 78.66-6.77 4.11-7.84 9.08-8.01 10.54z'
      fill='url(#banner_svg__SVGID_92_)'
    />
    <path className='banner_svg__st16' d='M44.96 217.71l.01 27.13 23.49-13.56v-27.15z' />
    <linearGradient
      id='banner_svg__SVGID_93_'
      gradientUnits='userSpaceOnUse'
      x1={42.343}
      y1={230.405}
      x2={44.967}
      y2={230.405}
    >
      <stop offset={0} stopColor='#005eff' />
      <stop offset={1} stopColor='#6aaeff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_93_)' d='M42.34 215.97l.01 27.13 2.62 1.74-.01-27.13z' />
    <linearGradient
      id='banner_svg__SVGID_94_'
      gradientUnits='userSpaceOnUse'
      x1={51.707}
      y1={225.91}
      x2={61.714}
      y2={225.91}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_94_)' d='M51.71 223.74v8.67l10-5.78v-7.22l-6.09 3.7-1.41-.81z' />
    <path
      className='banner_svg__st16'
      d='M32.59 207.4c-.29 2.14-1.1 4.38-2.37 6.43-.6.96-1.29 1.87-2.08 2.72-3.48 3.71-7.78 4.97-10.87 3.54-.03-.01-.05-.02-.08-.03l-.1-.05a5.3 5.3 0 01-1-.64c-.29-.23-.56-.48-.81-.77-.15-.18-.31-.38-.45-.58-2.8-4.03-1.4-11.24 3.15-16.1 2.67-2.85 5.82-4.25 8.54-4.1.47.02.92.09 1.36.21.34.09.66.21.98.35l.17.09c.87.44 1.64 1.1 2.25 1.98 1.27 1.83 1.68 4.32 1.31 6.95z'
    />
    <linearGradient
      id='banner_svg__SVGID_95_'
      gradientUnits='userSpaceOnUse'
      x1={11.257}
      y1={208.373}
      x2={28.86}
      y2={208.373}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M28.86 198.38a6.323 6.323 0 00-2.34-.56c-1.44-.08-2.99.27-4.54 1.05-.29.15-.59.31-.88.49-1.08.65-2.13 1.51-3.12 2.57-2.58 2.76-4.15 6.27-4.51 9.53-.07.61-.09 1.21-.08 1.79.06 1.8.52 3.46 1.44 4.78.14.21.3.4.45.58.26.29.52.54.81.77.31.25.65.46 1 .64l-2.14-1.07-.06-.03c-.01-.01-.02-.02-.03-.02-.84-.43-1.57-1.08-2.17-1.94-.6-.86-1.01-1.87-1.23-2.97-.25-1.21-.28-2.52-.1-3.88.42-3.18 1.97-6.57 4.47-9.25 1.06-1.14 2.21-2.04 3.37-2.71.85-.49 1.72-.86 2.57-1.09 1.79-.49 3.52-.41 4.97.27l.29.14 1.83.91z'
      fill='url(#banner_svg__SVGID_95_)'
    />
    <linearGradient
      id='banner_svg__SVGID_96_'
      gradientUnits='userSpaceOnUse'
      x1={17.233}
      y1={208.975}
      x2={28.471}
      y2={208.975}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M28.4 207.91c-.17 1.25-.64 2.55-1.38 3.74-.34.56-.75 1.09-1.21 1.58-2.03 2.16-4.53 2.89-6.32 2.06-.02-.01-.03-.02-.04-.02l-.06-.03c-.2-.1-.4-.23-.58-.37a3.31 3.31 0 01-.47-.45c-.09-.11-.18-.22-.26-.34-1.63-2.35-.81-6.54 1.83-9.36 1.55-1.66 3.38-2.47 4.96-2.38.27.01.53.05.79.12.2.05.38.12.57.2l.1.05c.5.25.95.64 1.31 1.15.73 1.07.97 2.52.76 4.05z'
      fill='url(#banner_svg__SVGID_96_)'
    />
    <path
      className='banner_svg__st16'
      d='M168.21 129.27c-.29 2.14-1.1 4.38-2.37 6.43-.6.96-1.29 1.87-2.08 2.72-3.48 3.71-7.78 4.97-10.87 3.54-.03-.01-.05-.02-.08-.03l-.1-.05a5.3 5.3 0 01-1-.64c-.29-.23-.56-.48-.81-.77-.15-.18-.31-.38-.45-.58-2.8-4.03-1.4-11.24 3.15-16.1 2.67-2.85 5.82-4.25 8.54-4.1.47.02.92.09 1.36.21.34.09.66.21.98.35l.17.09c.87.44 1.64 1.1 2.25 1.98 1.27 1.83 1.67 4.32 1.31 6.95z'
    />
    <linearGradient
      id='banner_svg__SVGID_97_'
      gradientUnits='userSpaceOnUse'
      x1={146.869}
      y1={130.241}
      x2={164.472}
      y2={130.241}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M164.47 120.24a6.323 6.323 0 00-2.34-.56c-1.44-.08-2.99.27-4.54 1.05-.29.15-.59.31-.88.49-1.08.65-2.13 1.51-3.12 2.57-2.58 2.76-4.15 6.27-4.51 9.53-.07.61-.09 1.21-.08 1.79.06 1.8.52 3.46 1.44 4.78.14.21.3.4.45.58.26.29.52.54.81.77.31.25.65.46 1 .64l-2.14-1.07-.06-.03c-.01-.01-.02-.02-.03-.02-.84-.43-1.57-1.08-2.17-1.94-.6-.86-1.01-1.87-1.23-2.97-.25-1.21-.28-2.52-.1-3.88.42-3.18 1.97-6.57 4.47-9.25 1.06-1.14 2.21-2.04 3.37-2.71.85-.49 1.72-.86 2.57-1.09 1.79-.49 3.52-.41 4.97.27l.29.14 1.83.91z'
      fill='url(#banner_svg__SVGID_97_)'
    />
    <linearGradient
      id='banner_svg__SVGID_98_'
      gradientUnits='userSpaceOnUse'
      x1={152.845}
      y1={130.843}
      x2={164.083}
      y2={130.843}
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path
      d='M164.01 129.77c-.17 1.25-.64 2.55-1.38 3.74-.34.56-.75 1.09-1.21 1.58-2.03 2.16-4.53 2.89-6.32 2.06-.02-.01-.03-.02-.04-.02l-.06-.03c-.2-.1-.4-.23-.58-.37a3.31 3.31 0 01-.47-.45c-.09-.11-.18-.22-.26-.34-1.63-2.35-.81-6.54 1.83-9.36 1.55-1.66 3.38-2.47 4.96-2.38.27.01.53.05.79.12.2.05.38.12.57.2l.1.05c.5.25.95.64 1.31 1.15.74 1.08.97 2.52.76 4.05z'
      fill='url(#banner_svg__SVGID_98_)'
    />
    <linearGradient
      id='banner_svg__SVGID_99_'
      gradientUnits='userSpaceOnUse'
      x1={42.343}
      y1={210.111}
      x2={68.462}
      y2={210.111}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_99_)' d='M44.96 217.71l23.5-13.58-2.87-1.62-23.25 13.46z' />
    <path className='banner_svg__st16' d='M87.33 194.33l.01 27.13 23.49-13.56v-27.15z' />
    <linearGradient
      id='banner_svg__SVGID_100_'
      gradientUnits='userSpaceOnUse'
      x1={84.716}
      y1={207.023}
      x2={87.339}
      y2={207.023}
    >
      <stop offset={0} stopColor='#005eff' />
      <stop offset={1} stopColor='#6aaeff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_100_)' d='M84.72 192.58v27.14l2.62 1.74-.01-27.13z' />
    <linearGradient
      id='banner_svg__SVGID_101_'
      gradientUnits='userSpaceOnUse'
      x1={94.079}
      y1={202.528}
      x2={104.087}
      y2={202.528}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_101_)' d='M94.08 200.36v8.67l10.01-5.78v-7.22l-6.09 3.7-1.42-.81z' />
    <linearGradient
      id='banner_svg__SVGID_102_'
      gradientUnits='userSpaceOnUse'
      x1={84.716}
      y1={186.729}
      x2={110.835}
      y2={186.729}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_102_)' d='M87.33 194.33l23.5-13.58-2.87-1.62-23.24 13.45z' />
    <path className='banner_svg__st16' d='M127.09 173.16l.01 27.14 23.49-13.57v-27.15z' />
    <linearGradient
      id='banner_svg__SVGID_103_'
      gradientUnits='userSpaceOnUse'
      x1={124.472}
      y1={185.857}
      x2={127.096}
      y2={185.857}
    >
      <stop offset={0} stopColor='#005eff' />
      <stop offset={1} stopColor='#6aaeff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_103_)' d='M124.47 171.42l.01 27.13 2.62 1.75-.01-27.14z' />
    <linearGradient
      id='banner_svg__SVGID_104_'
      gradientUnits='userSpaceOnUse'
      x1={133.836}
      y1={181.363}
      x2={143.844}
      y2={181.363}
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_104_)' d='M133.84 179.2v8.66l10-5.78v-7.22l-6.09 3.71-1.41-.82z' />
    <linearGradient
      id='banner_svg__SVGID_105_'
      gradientUnits='userSpaceOnUse'
      x1={124.472}
      y1={165.563}
      x2={150.592}
      y2={165.563}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_105_)' d='M127.09 173.16l23.5-13.58-2.87-1.62-23.25 13.46z' />
    <linearGradient
      id='banner_svg__SVGID_106_'
      gradientUnits='userSpaceOnUse'
      x1={-983.706}
      y1={270.903}
      x2={-955.119}
      y2={270.903}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_106_)' d='M86.07 270.9l14.29 8.25 14.3-8.25-14.3-8.24z' />
    <path className='banner_svg__st16' d='M86.07 270.9v41.26l14.3 8.24-.01-41.25z' />
    <linearGradient
      id='banner_svg__SVGID_107_'
      gradientUnits='userSpaceOnUse'
      x1={-969.416}
      y1={295.651}
      x2={-955.119}
      y2={295.651}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_107_)' d='M100.37 320.4l14.29-8.25V270.9l-14.3 8.25z' />
    <linearGradient
      id='banner_svg__SVGID_108_'
      gradientUnits='userSpaceOnUse'
      x1={-952.287}
      y1={263.7}
      x2={-923.7}
      y2={263.7}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#fd8d1f' />
      <stop offset={1} stopColor='#fd5c1f' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_108_)' d='M117.49 263.69l14.29 8.25 14.3-8.24-14.3-8.24z' />
    <path className='banner_svg__st16' d='M117.49 263.69v66.7l14.3 8.25-.01-66.7z' />
    <linearGradient
      id='banner_svg__SVGID_109_'
      gradientUnits='userSpaceOnUse'
      x1={-937.998}
      y1={301.169}
      x2={-923.7}
      y2={301.169}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_109_)' d='M131.79 338.64l14.29-8.25V263.7l-14.3 8.24z' />
    <linearGradient
      id='banner_svg__SVGID_110_'
      gradientUnits='userSpaceOnUse'
      x1={-920.84}
      y1={255.569}
      x2={-892.253}
      y2={255.569}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#ff0007' />
      <stop offset={1} stopColor='#ffaaac' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_110_)' d='M148.94 255.56l14.29 8.25 14.29-8.24-14.29-8.24z' />
    <path className='banner_svg__st16' d='M148.94 255.57v92.13l14.3 8.25-.01-92.14z' />
    <linearGradient
      id='banner_svg__SVGID_111_'
      gradientUnits='userSpaceOnUse'
      x1={-906.551}
      y1={305.758}
      x2={-892.253}
      y2={305.758}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path fill='url(#banner_svg__SVGID_111_)' d='M163.24 355.95l14.28-8.25v-92.13l-14.29 8.24z' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_30_'
        filterUnits='userSpaceOnUse'
        x={152.38}
        y={282.21}
        width={50.2}
        height={59.11}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={152.38} y={282.21} width={50.2} height={59.11} id='banner_svg__SVGID_112_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_30_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_31_'
            filterUnits='userSpaceOnUse'
            x={152.38}
            y={282.21}
            width={50.2}
            height={59.11}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_113_'
          gradientUnits='userSpaceOnUse'
          x1={-917.393}
          y1={311.762}
          x2={-867.197}
          y2={311.762}
          gradientTransform='translate(1069.777)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.471} stopColor='#f6f6f6' />
          <stop offset={0.999} stopColor='#e6e6e6' />
        </linearGradient>
        <path
          d='M185.19 341.31c-7.09 0-14.68-3.81-21.05-10.62a44.856 44.856 0 01-5.34-6.97c-3.23-5.21-5.33-10.92-6.08-16.51-.99-7.04.23-13.44 3.42-18.04 2.89-4.16 7.1-6.55 12.19-6.92 7.49-.55 15.67 3.31 22.47 10.56 5.64 6.02 9.58 13.63 11.1 21.44 1.53 7.86.44 14.99-3.1 20.07-2.89 4.17-7.12 6.56-12.21 6.93a13 13 0 01-1.4.06zm-28.48-51.74l.57.4c-2.99 4.31-4.12 10.36-3.19 17.06.73 5.4 2.76 10.92 5.89 15.96 1.5 2.42 3.24 4.69 5.17 6.75 6.41 6.85 14.39 10.64 21.34 10.13 4.66-.34 8.52-2.53 11.17-6.34 6.89-9.92 3.36-27.76-7.88-39.76-6.41-6.85-14.39-10.63-21.35-10.13-4.66.34-8.51 2.53-11.15 6.32l-.57-.39z'
          mask='url(#banner_svg__SVGID_112_)'
          fill='url(#banner_svg__SVGID_113_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_114_'
      gradientUnits='userSpaceOnUse'
      x1={-917.393}
      y1={311.762}
      x2={-867.197}
      y2={311.762}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.51} stopColor='#b7d2ff' />
      <stop offset={0.913} stopColor='#dfeaff' />
      <stop offset={0.999} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M185.19 341.31c-7.09 0-14.68-3.81-21.05-10.62a44.856 44.856 0 01-5.34-6.97c-3.23-5.21-5.33-10.92-6.08-16.51-.99-7.04.23-13.44 3.42-18.04 2.89-4.16 7.1-6.55 12.19-6.92 7.49-.55 15.67 3.31 22.47 10.56 5.64 6.02 9.58 13.63 11.1 21.44 1.53 7.86.44 14.99-3.1 20.07-2.89 4.17-7.12 6.56-12.21 6.93a13 13 0 01-1.4.06zm-28.48-51.74l.57.4c-2.99 4.31-4.12 10.36-3.19 17.06.73 5.4 2.76 10.92 5.89 15.96 1.5 2.42 3.24 4.69 5.17 6.75 6.41 6.85 14.39 10.64 21.34 10.13 4.66-.34 8.52-2.53 11.17-6.34 6.89-9.92 3.36-27.76-7.88-39.76-6.41-6.85-14.39-10.63-21.35-10.13-4.66.34-8.51 2.53-11.15 6.32l-.57-.39z'
      fill='url(#banner_svg__SVGID_114_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_32_'
        filterUnits='userSpaceOnUse'
        x={159.12}
        y={290.65}
        width={36.3}
        height={42.75}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={159.12} y={290.65} width={36.3} height={42.75} id='banner_svg__SVGID_115_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_32_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_33_'
            filterUnits='userSpaceOnUse'
            x={159.12}
            y={290.65}
            width={36.3}
            height={42.75}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_116_'
          gradientUnits='userSpaceOnUse'
          x1={-910.656}
          y1={312.029}
          x2={-874.352}
          y2={312.029}
          gradientTransform='translate(1069.777)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.471} stopColor='#f6f6f6' />
          <stop offset={0.999} stopColor='#e6e6e6' />
        </linearGradient>
        <path
          d='M193.97 310.14l-.98.6c2.05 6.28 1.79 12.65-1.2 16.96-1.9 2.73-4.67 4.3-8 4.54-4.82.35-10.32-2.17-14.84-6.77l-.99.61c4.54 4.7 9.89 7.33 14.9 7.33.34 0 .68-.01 1.02-.04 3.69-.27 6.75-2.01 8.85-5.03 3.2-4.64 3.47-11.51 1.24-18.2zm-22.31-18.35c4.78 0 10.11 2.68 14.45 7.32.62.66 1.2 1.34 1.75 2.05l.98-.58c-.6-.77-1.23-1.52-1.91-2.24-4.91-5.25-10.84-8.03-16.25-7.64-3.69.27-6.75 2-8.84 5.02-2.31 3.33-3.19 7.96-2.48 13.05.36 2.66 1.14 5.35 2.3 7.96l.98-.58c-1.09-2.47-1.82-5.02-2.16-7.53-.16-1.1-.23-2.18-.23-3.23 0-3.51.86-6.65 2.52-9.03 1.89-2.72 4.65-4.29 7.99-4.53.3-.03.6-.04.9-.04z'
          mask='url(#banner_svg__SVGID_115_)'
          fill='url(#banner_svg__SVGID_116_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_117_'
      gradientUnits='userSpaceOnUse'
      x1={-910.656}
      y1={312.029}
      x2={-874.352}
      y2={312.029}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.51} stopColor='#b7d2ff' />
      <stop offset={0.913} stopColor='#dfeaff' />
      <stop offset={0.999} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M193.97 310.14l-.98.6c2.05 6.28 1.79 12.65-1.2 16.96-1.9 2.73-4.67 4.3-8 4.54-4.82.35-10.32-2.17-14.84-6.77l-.99.61c4.54 4.7 9.89 7.33 14.9 7.33.34 0 .68-.01 1.02-.04 3.69-.27 6.75-2.01 8.85-5.03 3.2-4.64 3.47-11.51 1.24-18.2zm-22.31-18.35c4.78 0 10.11 2.68 14.45 7.32.62.66 1.2 1.34 1.75 2.05l.98-.58c-.6-.77-1.23-1.52-1.91-2.24-4.91-5.25-10.84-8.03-16.25-7.64-3.69.27-6.75 2-8.84 5.02-2.31 3.33-3.19 7.96-2.48 13.05.36 2.66 1.14 5.35 2.3 7.96l.98-.58c-1.09-2.47-1.82-5.02-2.16-7.53-.16-1.1-.23-2.18-.23-3.23 0-3.51.86-6.65 2.52-9.03 1.89-2.72 4.65-4.29 7.99-4.53.3-.03.6-.04.9-.04z'
      fill='url(#banner_svg__SVGID_117_)'
    />
    <linearGradient
      id='banner_svg__SVGID_118_'
      gradientUnits='userSpaceOnUse'
      x1={-898.679}
      y1={313.122}
      x2={-888.747}
      y2={313.122}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M171.84 308.61c-.65.94-.86 2.22-.67 3.57.15 1.1.56 2.25 1.22 3.3.31.49.66.96 1.07 1.4 2.34 2.49 5.39 2.83 6.84.76 1.44-2.07.72-5.77-1.62-8.27s-5.4-2.84-6.84-.76z'
      fill='url(#banner_svg__SVGID_118_)'
    />
    <linearGradient
      id='banner_svg__SVGID_119_'
      gradientUnits='userSpaceOnUse'
      x1={-892.894}
      y1={310.921}
      x2={-887.322}
      y2={310.921}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M177.3 308.39c-.37.53-.48 1.25-.38 2 .08.62.32 1.26.68 1.85.17.28.37.54.6.78 1.31 1.4 3.03 1.59 3.83.42.81-1.16.4-3.24-.91-4.64-1.3-1.38-3.01-1.58-3.82-.41z'
      fill='url(#banner_svg__SVGID_119_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_34_'
        filterUnits='userSpaceOnUse'
        x={101.5}
        y={293.41}
        width={23.51}
        height={27.69}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={101.5} y={293.41} width={23.51} height={27.69} id='banner_svg__SVGID_120_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_34_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_35_'
            filterUnits='userSpaceOnUse'
            x={101.5}
            y={293.41}
            width={23.51}
            height={27.69}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_121_'
          gradientUnits='userSpaceOnUse'
          x1={-968.275}
          y1={307.249}
          x2={-944.761}
          y2={307.249}
          gradientTransform='translate(1069.777)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.168} stopColor='#f6f6f6' />
          <stop offset={0.446} stopColor='#dcdcdc' />
          <stop offset={0.798} stopColor='#b4b4b4' />
          <stop offset={1} stopColor='#999' />
        </linearGradient>
        <path
          d='M116.87 321.09c-3.32 0-6.88-1.79-9.86-4.97-.93-1-1.77-2.1-2.5-3.27-1.51-2.44-2.5-5.12-2.85-7.73-.46-3.3.11-6.3 1.6-8.45 1.35-1.95 3.33-3.07 5.71-3.24 3.51-.26 7.34 1.55 10.53 4.95 2.64 2.82 4.49 6.39 5.2 10.04.72 3.68.2 7.02-1.45 9.4-1.36 1.95-3.33 3.07-5.72 3.25-.22.01-.44.02-.66.02zm-13.34-24.23l.27.19c-1.4 2.02-1.93 4.85-1.49 7.99.34 2.53 1.29 5.11 2.76 7.48.7 1.13 1.52 2.2 2.42 3.16 3 3.21 6.74 4.98 10 4.75 2.18-.16 3.99-1.19 5.23-2.97 3.23-4.65 1.57-13-3.69-18.62-3-3.21-6.74-4.98-10-4.74-2.18.16-3.99 1.18-5.22 2.96l-.28-.2z'
          mask='url(#banner_svg__SVGID_120_)'
          fill='url(#banner_svg__SVGID_121_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_122_'
      gradientUnits='userSpaceOnUse'
      x1={-968.275}
      y1={307.249}
      x2={-944.761}
      y2={307.249}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.511} stopColor='#b7d2ff' />
      <stop offset={0.914} stopColor='#dfeaff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M116.87 321.09c-3.32 0-6.88-1.79-9.86-4.97-.93-1-1.77-2.1-2.5-3.27-1.51-2.44-2.5-5.12-2.85-7.73-.46-3.3.11-6.3 1.6-8.45 1.35-1.95 3.33-3.07 5.71-3.24 3.51-.26 7.34 1.55 10.53 4.95 2.64 2.82 4.49 6.39 5.2 10.04.72 3.68.2 7.02-1.45 9.4-1.36 1.95-3.33 3.07-5.72 3.25-.22.01-.44.02-.66.02zm-13.34-24.23l.27.19c-1.4 2.02-1.93 4.85-1.49 7.99.34 2.53 1.29 5.11 2.76 7.48.7 1.13 1.52 2.2 2.42 3.16 3 3.21 6.74 4.98 10 4.75 2.18-.16 3.99-1.19 5.23-2.97 3.23-4.65 1.57-13-3.69-18.62-3-3.21-6.74-4.98-10-4.74-2.18.16-3.99 1.18-5.22 2.96l-.28-.2z'
      fill='url(#banner_svg__SVGID_122_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_36_'
        filterUnits='userSpaceOnUse'
        x={104.66}
        y={297.36}
        width={17.01}
        height={20.03}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={104.66} y={297.36} width={17.01} height={20.03} id='banner_svg__SVGID_123_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_36_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_37_'
            filterUnits='userSpaceOnUse'
            x={104.66}
            y={297.36}
            width={17.01}
            height={20.03}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_124_'
          gradientUnits='userSpaceOnUse'
          x1={-965.119}
          y1={307.375}
          x2={-948.113}
          y2={307.375}
          gradientTransform='translate(1069.777)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.168} stopColor='#f6f6f6' />
          <stop offset={0.446} stopColor='#dcdcdc' />
          <stop offset={0.798} stopColor='#b4b4b4' />
          <stop offset={1} stopColor='#999' />
        </linearGradient>
        <path
          d='M120.98 306.49l-.46.28c.96 2.94.84 5.93-.56 7.94-.89 1.28-2.19 2.01-3.75 2.13-2.26.16-4.84-1.02-6.95-3.17l-.46.28c2.12 2.2 4.63 3.43 6.98 3.43.16 0 .32-.01.48-.02 1.73-.13 3.16-.94 4.15-2.36 1.49-2.16 1.62-5.38.57-8.51zm-10.45-8.6c2.24 0 4.74 1.26 6.77 3.43.29.31.56.63.82.96l.46-.27c-.28-.36-.58-.71-.89-1.05-2.3-2.46-5.08-3.76-7.61-3.58-1.73.13-3.16.94-4.14 2.35-1.08 1.56-1.49 3.73-1.16 6.11.17 1.24.53 2.51 1.08 3.73l.46-.27a13.2 13.2 0 01-1.01-3.53c-.07-.52-.11-1.02-.11-1.51 0-1.65.4-3.11 1.18-4.23.89-1.27 2.18-2.01 3.74-2.12.13-.01.27-.02.41-.02z'
          mask='url(#banner_svg__SVGID_123_)'
          fill='url(#banner_svg__SVGID_124_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_125_'
      gradientUnits='userSpaceOnUse'
      x1={-965.119}
      y1={307.375}
      x2={-948.113}
      y2={307.375}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.511} stopColor='#b7d2ff' />
      <stop offset={0.914} stopColor='#dfeaff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M120.98 306.49l-.46.28c.96 2.94.84 5.93-.56 7.94-.89 1.28-2.19 2.01-3.75 2.13-2.26.16-4.84-1.02-6.95-3.17l-.46.28c2.12 2.2 4.63 3.43 6.98 3.43.16 0 .32-.01.48-.02 1.73-.13 3.16-.94 4.15-2.36 1.49-2.16 1.62-5.38.57-8.51zm-10.45-8.6c2.24 0 4.74 1.26 6.77 3.43.29.31.56.63.82.96l.46-.27c-.28-.36-.58-.71-.89-1.05-2.3-2.46-5.08-3.76-7.61-3.58-1.73.13-3.16.94-4.14 2.35-1.08 1.56-1.49 3.73-1.16 6.11.17 1.24.53 2.51 1.08 3.73l.46-.27a13.2 13.2 0 01-1.01-3.53c-.07-.52-.11-1.02-.11-1.51 0-1.65.4-3.11 1.18-4.23.89-1.27 2.18-2.01 3.74-2.12.13-.01.27-.02.41-.02z'
      fill='url(#banner_svg__SVGID_125_)'
    />
    <linearGradient
      id='banner_svg__SVGID_126_'
      gradientUnits='userSpaceOnUse'
      x1={-959.509}
      y1={307.886}
      x2={-954.856}
      y2={307.886}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M110.62 305.77c-.31.44-.4 1.04-.32 1.67.07.52.26 1.05.57 1.55.14.23.31.45.5.65 1.09 1.17 2.53 1.33 3.2.35.67-.97.34-2.71-.76-3.87-1.09-1.16-2.52-1.32-3.19-.35z'
      fill='url(#banner_svg__SVGID_126_)'
    />
    <linearGradient
      id='banner_svg__SVGID_127_'
      gradientUnits='userSpaceOnUse'
      x1={-956.798}
      y1={306.855}
      x2={-954.189}
      y2={306.855}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M113.17 305.67c-.17.25-.23.58-.18.94.04.29.15.59.32.87.08.13.17.25.28.37.61.66 1.42.74 1.8.2.38-.54.19-1.52-.42-2.17-.61-.67-1.42-.76-1.8-.21z'
      fill='url(#banner_svg__SVGID_127_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_38_'
        filterUnits='userSpaceOnUse'
        x={76.01}
        y={279.98}
        width={14.76}
        height={17.38}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={76.01} y={279.98} width={14.76} height={17.38} id='banner_svg__SVGID_128_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_38_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_39_'
            filterUnits='userSpaceOnUse'
            x={76.01}
            y={279.98}
            width={14.76}
            height={17.38}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_129_'
          gradientUnits='userSpaceOnUse'
          x1={-993.766}
          y1={288.672}
          x2={-979.006}
          y2={288.672}
          gradientTransform='translate(1069.777)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.168} stopColor='#f6f6f6' />
          <stop offset={0.446} stopColor='#dcdcdc' />
          <stop offset={0.798} stopColor='#b4b4b4' />
          <stop offset={1} stopColor='#999' />
        </linearGradient>
        <path
          d='M85.66 297.36c-2.08 0-4.32-1.12-6.19-3.12-.59-.63-1.11-1.32-1.57-2.05-.95-1.53-1.57-3.21-1.79-4.85-.29-2.07.07-3.95 1.01-5.3.85-1.22 2.09-1.93 3.59-2.03 2.2-.16 4.61.97 6.61 3.11 1.66 1.77 2.82 4.01 3.26 6.3.45 2.31.13 4.41-.91 5.9-.85 1.22-2.09 1.93-3.59 2.04h-.42zm-8.38-15.21l.17.12c-.88 1.27-1.21 3.05-.94 5.02.21 1.59.81 3.21 1.73 4.69.44.71.95 1.38 1.52 1.99 1.89 2.01 4.23 3.13 6.28 2.98 1.37-.1 2.51-.74 3.28-1.86 2.03-2.92.99-8.16-2.32-11.69-1.89-2.01-4.23-3.13-6.28-2.98-1.37.1-2.5.74-3.28 1.86l-.16-.13z'
          mask='url(#banner_svg__SVGID_128_)'
          fill='url(#banner_svg__SVGID_129_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_130_'
      gradientUnits='userSpaceOnUse'
      x1={-993.766}
      y1={288.672}
      x2={-979.006}
      y2={288.672}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.511} stopColor='#b7d2ff' />
      <stop offset={0.914} stopColor='#dfeaff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M85.66 297.36c-2.08 0-4.32-1.12-6.19-3.12-.59-.63-1.11-1.32-1.57-2.05-.95-1.53-1.57-3.21-1.79-4.85-.29-2.07.07-3.95 1.01-5.3.85-1.22 2.09-1.93 3.59-2.03 2.2-.16 4.61.97 6.61 3.11 1.66 1.77 2.82 4.01 3.26 6.3.45 2.31.13 4.41-.91 5.9-.85 1.22-2.09 1.93-3.59 2.04h-.42zm-8.38-15.21l.17.12c-.88 1.27-1.21 3.05-.94 5.02.21 1.59.81 3.21 1.73 4.69.44.71.95 1.38 1.52 1.99 1.89 2.01 4.23 3.13 6.28 2.98 1.37-.1 2.51-.74 3.28-1.86 2.03-2.92.99-8.16-2.32-11.69-1.89-2.01-4.23-3.13-6.28-2.98-1.37.1-2.5.74-3.28 1.86l-.16-.13z'
      fill='url(#banner_svg__SVGID_130_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_40_'
        filterUnits='userSpaceOnUse'
        x={77.99}
        y={282.47}
        width={10.68}
        height={12.57}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={77.99} y={282.47} width={10.68} height={12.57} id='banner_svg__SVGID_131_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_40_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_41_'
            filterUnits='userSpaceOnUse'
            x={77.99}
            y={282.47}
            width={10.68}
            height={12.57}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_132_'
          gradientUnits='userSpaceOnUse'
          x1={-991.785}
          y1={288.751}
          x2={-981.11}
          y2={288.751}
          gradientTransform='translate(1069.777)'
        >
          <stop offset={0} stopColor='#fff' />
          <stop offset={0.168} stopColor='#f6f6f6' />
          <stop offset={0.446} stopColor='#dcdcdc' />
          <stop offset={0.798} stopColor='#b4b4b4' />
          <stop offset={1} stopColor='#999' />
        </linearGradient>
        <path
          d='M88.24 288.19l-.29.18c.6 1.85.53 3.72-.35 4.99-.56.8-1.37 1.26-2.35 1.34-1.42.1-3.04-.64-4.37-1.99l-.29.18c1.33 1.38 2.91 2.15 4.38 2.15.1 0 .2 0 .3-.01 1.09-.08 1.99-.59 2.6-1.48.95-1.37 1.02-3.39.37-5.36zm-6.56-5.39c1.41 0 2.97.79 4.25 2.15.18.19.35.4.52.6l.29-.17c-.18-.23-.36-.45-.56-.66-1.45-1.54-3.19-2.36-4.78-2.25-1.09.08-1.98.59-2.6 1.47-.68.98-.94 2.34-.73 3.84.1.78.33 1.57.68 2.34l.29-.17c-.32-.73-.53-1.47-.63-2.21-.05-.32-.07-.64-.07-.95 0-1.03.25-1.95.74-2.65.56-.8 1.37-1.26 2.35-1.33.07-.01.16-.01.25-.01z'
          mask='url(#banner_svg__SVGID_131_)'
          fill='url(#banner_svg__SVGID_132_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_133_'
      gradientUnits='userSpaceOnUse'
      x1={-991.785}
      y1={288.751}
      x2={-981.11}
      y2={288.751}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#94bfff' />
      <stop offset={0.192} stopColor='#9dc4ff' />
      <stop offset={0.511} stopColor='#b7d2ff' />
      <stop offset={0.914} stopColor='#dfeaff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M88.24 288.19l-.29.18c.6 1.85.53 3.72-.35 4.99-.56.8-1.37 1.26-2.35 1.34-1.42.1-3.04-.64-4.37-1.99l-.29.18c1.33 1.38 2.91 2.15 4.38 2.15.1 0 .2 0 .3-.01 1.09-.08 1.99-.59 2.6-1.48.95-1.37 1.02-3.39.37-5.36zm-6.56-5.39c1.41 0 2.97.79 4.25 2.15.18.19.35.4.52.6l.29-.17c-.18-.23-.36-.45-.56-.66-1.45-1.54-3.19-2.36-4.78-2.25-1.09.08-1.98.59-2.6 1.47-.68.98-.94 2.34-.73 3.84.1.78.33 1.57.68 2.34l.29-.17c-.32-.73-.53-1.47-.63-2.21-.05-.32-.07-.64-.07-.95 0-1.03.25-1.95.74-2.65.56-.8 1.37-1.26 2.35-1.33.07-.01.16-.01.25-.01z'
      fill='url(#banner_svg__SVGID_133_)'
    />
    <linearGradient
      id='banner_svg__SVGID_134_'
      gradientUnits='userSpaceOnUse'
      x1={-988.263}
      y1={289.072}
      x2={-985.343}
      y2={289.072}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M81.73 287.74c-.19.28-.25.65-.2 1.05.04.32.17.66.36.97.09.15.19.28.31.41.69.73 1.59.83 2.01.22.42-.61.21-1.7-.48-2.43-.67-.73-1.58-.83-2-.22z'
      fill='url(#banner_svg__SVGID_134_)'
    />
    <linearGradient
      id='banner_svg__SVGID_135_'
      gradientUnits='userSpaceOnUse'
      x1={-986.562}
      y1={288.425}
      x2={-984.924}
      y2={288.425}
      gradientTransform='translate(1069.777)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M83.34 287.68c-.11.16-.14.37-.11.59a1.364 1.364 0 00.38.78c.39.41.89.47 1.13.12.24-.34.12-.95-.27-1.36-.39-.42-.9-.47-1.13-.13z'
      fill='url(#banner_svg__SVGID_135_)'
    />
    <linearGradient
      id='banner_svg__SVGID_136_'
      gradientUnits='userSpaceOnUse'
      x1={360.251}
      y1={316.913}
      x2={370.694}
      y2={316.913}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M360.69 314.94s-1.59 5.1 1.28 4.84 8.98-3.56 8.72-4.84-6.77-.14-6.77-.14l-2.06-.76-1.17.9z'
      fill='url(#banner_svg__SVGID_136_)'
    />
    <linearGradient
      id='banner_svg__SVGID_137_'
      gradientUnits='userSpaceOnUse'
      x1={352.889}
      y1={315.135}
      x2={363.231}
      y2={315.135}
    >
      <stop offset={0} stopColor='#025' />
      <stop offset={1} stopColor='#003a7f' />
    </linearGradient>
    <path
      d='M353.33 313.18s-1.58 5.05 1.26 4.79c2.84-.26 8.9-3.53 8.63-4.79-.26-1.26-6.7-.14-6.7-.14l-2.04-.75-1.15.89z'
      fill='url(#banner_svg__SVGID_137_)'
    />
    <linearGradient
      id='banner_svg__SVGID_138_'
      gradientUnits='userSpaceOnUse'
      x1={357.035}
      y1={297.912}
      x2={363.467}
      y2={297.912}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M357.04 283.44s1.6 5.49 1.41 8.76c-.18 3.27 2.19 23.44 2.19 23.44s.27.46 1.25.4c.97-.06 1.52-.52 1.52-.52s.31-32.1-.49-34.3c-.8-2.2-5.89-1.21-5.89-1.21v3.43z'
      fill='url(#banner_svg__SVGID_138_)'
    />
    <linearGradient
      id='banner_svg__SVGID_139_'
      gradientUnits='userSpaceOnUse'
      x1={349.872}
      y1={296.574}
      x2={356.241}
      y2={296.574}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M349.87 282.24s1.58 5.43 1.4 8.67c-.18 3.24 2.17 23.21 2.17 23.21s.27.45 1.24.39c.96-.06 1.51-.51 1.51-.51s.31-31.78-.48-33.96c-.79-2.18-5.83-1.2-5.83-1.2v3.4z'
      fill='url(#banner_svg__SVGID_139_)'
    />
    <linearGradient
      id='banner_svg__SVGID_140_'
      gradientUnits='userSpaceOnUse'
      x1={347.178}
      y1={273.836}
      x2={367.075}
      y2={273.836}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M367.07 283.35s-2.29-1.05-4.02-1c-3.79.11-3.38 1.13-7.4 1.33-3.96.19-8.03-2.54-8.03-2.54.02-.52-1-2.26-.05-5.68.89-3.18.49-4.35.09-5.93.72-.76 1.9-1.52 3.7-1.14 3.23.68 9.01-1.04 9.01-1.04-2 .4-4.37.53-6.86-.05-.76-.18-1.45-.36-2.07-.54-2.03-.59-3.3-1.18-4.09-1.66 0 0 1.01-.08 2.53-.21 1.88-.15 4.55-.36 7.03-.54 3.35-.24 6.36-.43 6.63-.33.64.22 1.73 1.66 1.73 1.66l1.8 17.67z'
      fill='url(#banner_svg__SVGID_140_)'
    />
    <linearGradient
      id='banner_svg__SVGID_141_'
      gradientUnits='userSpaceOnUse'
      x1={343.56}
      y1={243.672}
      x2={352.834}
      y2={243.672}
    >
      <stop offset={0} stopColor='#d4e3ff' />
      <stop offset={1} stopColor='#e9efff' />
    </linearGradient>
    <path
      d='M347.93 235.11s-4.63 8.53-4.36 12.05c.27 3.52 9.26 5.08 9.26 5.08l-.41-9.08-4.49-8.05z'
      fill='url(#banner_svg__SVGID_141_)'
    />
    <linearGradient
      id='banner_svg__SVGID_142_'
      gradientUnits='userSpaceOnUse'
      x1={354.319}
      y1={224.111}
      x2={362.932}
      y2={224.111}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M360.85 221.38s1.66 2.46 1.25 3.34c-.2.43 1.6 2.86.42 3.68-1.62 1.13-4.11.97-6.72-1.13-2.62-2.1-.97-5.9-.97-5.9l2.71-2.25 3.31 2.26z'
      fill='url(#banner_svg__SVGID_142_)'
    />
    <linearGradient
      id='banner_svg__SVGID_143_'
      gradientUnits='userSpaceOnUse'
      x1={374.094}
      y1={229.097}
      x2={378.52}
      y2={229.097}
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M374.09 231.08s2.52-6.87 2.96-6.43-.53 2.63.25 3.53 1.81 1.32.79 2.59-3.78 2.8-3.78 2.8l-.22-2.49z'
      fill='url(#banner_svg__SVGID_143_)'
    />
    <linearGradient
      id='banner_svg__SVGID_144_'
      gradientUnits='userSpaceOnUse'
      x1={346.127}
      y1={249.067}
      x2={375.8}
      y2={249.067}
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M368.56 248.21c-3.56-1.82-4.91-4.26-5.08-4.57.11.25.8 1.95.43 2.79-.12.28-.3.53-.47.78 0 0-.01.02-.04.06-.02.02-.04.05-.05.07-.01.02-.03.04-.04.06-.36.53-.65 1.11-.37 2.05.27.9.77 1.8 1.28 3.34.35 1.04.7 2.36.99 4.17.72 4.48.81 8.32.81 8.32s-.26.16-.73.39c-.93.46-2.69 1.22-4.91 1.66-2 .4-4.37.53-6.86-.05-.76-.18-1.45-.36-2.07-.54-2.03-.59-3.3-1.18-4.09-1.66-1.05-.64-1.24-1.08-1.24-1.08s.06-.54.15-1.41c.3-2.73 1-8.7 1.55-11.28.48-2.23-.07-5.14-.47-8.21-.21-1.63-.38-3.3-.32-4.94.2-6.13 7.58-6.37 7.58-6.37s5.32-.28 8.32 3.73c2.03 2.71 3.87 4.53 5.06 5 .57.23.99.14 1.2-.31.14-.3.39-.97.72-1.84v-.01c1.22-3.19 3.52-8.94 5.31-7.74 2.3 1.52-2.84 19.53-6.66 17.59z'
      fill='url(#banner_svg__SVGID_144_)'
    />
    <linearGradient
      id='banner_svg__SVGID_145_'
      gradientUnits='userSpaceOnUse'
      x1={358.904}
      y1={259.313}
      x2={363.559}
      y2={259.313}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M358.9 259.71s1.92-.3 2.83-.73c.91-.43 1.83-1.43 1.83-1.43s-.43 1.7-1.21 2.57-1 .96-1 .96.42-1.46-.38-1.35c-.81.11-2.07-.02-2.07-.02z'
      fill='url(#banner_svg__SVGID_145_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_42_'
        filterUnits='userSpaceOnUse'
        x={360.19}
        y={247.39}
        width={4.02}
        height={5.4}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={360.19} y={247.39} width={4.02} height={5.4} id='banner_svg__SVGID_146_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_42_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_43_'
            filterUnits='userSpaceOnUse'
            x={360.19}
            y={247.39}
            width={4.02}
            height={5.4}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_147_'
          gradientUnits='userSpaceOnUse'
          x1={360.191}
          y1={250.088}
          x2={364.213}
          y2={250.088}
        >
          <stop offset={0.001} stopColor='#fff' />
          <stop offset={0.13} stopColor='#f9f9f9' />
          <stop offset={0.302} stopColor='#e8e8e8' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.716} stopColor='#a5a5a5' />
          <stop offset={0.944} stopColor='#737373' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M364.21 252.79c-.11-.12-1.95-2.15-3.14-2.48-1.23-.34-1.16-.43.16-1.1 1.02-.51 1.78-1.43 2.07-1.82-.36.53-.65 1.11-.37 2.05.27.9.77 1.81 1.28 3.35z'
          mask='url(#banner_svg__SVGID_146_)'
          fill='url(#banner_svg__SVGID_147_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_148_'
      gradientUnits='userSpaceOnUse'
      x1={360.191}
      y1={250.088}
      x2={364.213}
      y2={250.088}
    >
      <stop offset={0.001} stopColor='#94b9ff' />
      <stop offset={0.188} stopColor='#9abdff' />
      <stop offset={0.438} stopColor='#abc8ff' />
      <stop offset={0.723} stopColor='#c7dbff' />
      <stop offset={1} stopColor='#e9f2ff' />
    </linearGradient>
    <path
      d='M364.21 252.79c-.11-.12-1.95-2.15-3.14-2.48-1.23-.34-1.16-.43.16-1.1 1.02-.51 1.78-1.43 2.07-1.82-.36.53-.65 1.11-.37 2.05.27.9.77 1.81 1.28 3.35z'
      fill='url(#banner_svg__SVGID_148_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_44_'
        filterUnits='userSpaceOnUse'
        x={346.13}
        y={243.1}
        width={7.61}
        height={23.65}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={346.13} y={243.1} width={7.61} height={23.65} id='banner_svg__SVGID_149_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_44_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_45_'
            filterUnits='userSpaceOnUse'
            x={346.13}
            y={243.1}
            width={7.61}
            height={23.65}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_150_'
          gradientUnits='userSpaceOnUse'
          x1={346.127}
          y1={254.92}
          x2={353.74}
          y2={254.92}
        >
          <stop offset={0.001} stopColor='#fff' />
          <stop offset={0.13} stopColor='#f9f9f9' />
          <stop offset={0.302} stopColor='#e8e8e8' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.716} stopColor='#a5a5a5' />
          <stop offset={0.944} stopColor='#737373' />
          <stop offset={1} stopColor='#666' />
        </linearGradient>
        <path
          d='M352.83 256.65c-1.68 1.42-3.38 3.08-4.14 4.55-.57 1.1-.61 2.09.26 2.81.33.27.65.57.94.87.61.63 1.14 1.3 1.56 1.87-2.03-.59-3.3-1.18-4.09-1.66-1.05-.64-1.24-1.08-1.24-1.08s.06-.54.15-1.41c.3-2.73 1-8.7 1.55-11.28.48-2.23-.07-5.14-.47-8.21 0 0 4.24 2.93 3.1 4.29-1.14 1.37-1.96 4.04-.83 4.85 1.16.8 6.13 1.94 3.21 4.4z'
          mask='url(#banner_svg__SVGID_149_)'
          fill='url(#banner_svg__SVGID_150_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_151_'
      gradientUnits='userSpaceOnUse'
      x1={346.127}
      y1={254.92}
      x2={353.74}
      y2={254.92}
    >
      <stop offset={0.001} stopColor='#94b9ff' />
      <stop offset={0.188} stopColor='#9abdff' />
      <stop offset={0.438} stopColor='#abc8ff' />
      <stop offset={0.723} stopColor='#c7dbff' />
      <stop offset={1} stopColor='#e9f2ff' />
    </linearGradient>
    <path
      d='M352.83 256.65c-1.68 1.42-3.38 3.08-4.14 4.55-.57 1.1-.61 2.09.26 2.81.33.27.65.57.94.87.61.63 1.14 1.3 1.56 1.87-2.03-.59-3.3-1.18-4.09-1.66-1.05-.64-1.24-1.08-1.24-1.08s.06-.54.15-1.41c.3-2.73 1-8.7 1.55-11.28.48-2.23-.07-5.14-.47-8.21 0 0 4.24 2.93 3.1 4.29-1.14 1.37-1.96 4.04-.83 4.85 1.16.8 6.13 1.94 3.21 4.4z'
      fill='url(#banner_svg__SVGID_151_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_46_'
        filterUnits='userSpaceOnUse'
        x={367.99}
        y={238.37}
        width={1.92}
        height={3.05}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={367.99} y={238.37} width={1.92} height={3.05} id='banner_svg__SVGID_152_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_46_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_47_'
            filterUnits='userSpaceOnUse'
            x={367.99}
            y={238.37}
            width={1.92}
            height={3.05}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_153_'
          gradientUnits='userSpaceOnUse'
          x1={367.995}
          y1={239.896}
          x2={369.919}
          y2={239.896}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M369.86 241.16c.09.84-1.86-.63-1.86-.63.57.23.99.14 1.2-.31.14-.3.39-.97.72-1.84-.25.64-.15 1.94-.06 2.78z'
          mask='url(#banner_svg__SVGID_152_)'
          fill='url(#banner_svg__SVGID_153_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_154_'
      gradientUnits='userSpaceOnUse'
      x1={367.995}
      y1={239.896}
      x2={369.919}
      y2={239.896}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M369.86 241.16c.09.84-1.86-.63-1.86-.63.57.23.99.14 1.2-.31.14-.3.39-.97.72-1.84-.25.64-.15 1.94-.06 2.78z'
      fill='url(#banner_svg__SVGID_154_)'
    />
    <linearGradient
      id='banner_svg__SVGID_155_'
      gradientUnits='userSpaceOnUse'
      x1={347.315}
      y1={267.303}
      x2={360.383}
      y2={267.303}
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#002294' />
    </linearGradient>
    <path
      d='M360.38 267.33s-5.77 1.73-9.01 1.04c-1.8-.38-2.98.38-3.7 1.14-.03-.12-.06-.24-.09-.37-.43-1.78-.22-4.07-.22-4.07.78.48 2.06 1.07 4.09 1.66.62.18 1.3.36 2.07.54 2.49.59 4.86.46 6.86.06z'
      fill='url(#banner_svg__SVGID_155_)'
    />
    <path
      className='banner_svg__st182'
      d='M360.85 221.38s-2.3.45-1.26 3.7c1.04 3.24 2.09 4.79.5 5.93-1.59 1.14-.82 2.05-.39 3.1.99 2.37 1.35 6.24-3.89 5.83-5.25-.41-9.77-3.19-8.28-7.06.83-2.15 4.2-3.1 2.44-4.69-.91-.82-.93-3.29 0-5.9 1.2-3.39 3.18-5.66 6.8-5.11 3.61.54 4.08 4.2 4.08 4.2z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_48_'
        filterUnits='userSpaceOnUse'
        x={251.43}
        y={321.89}
        width={49.67}
        height={25.98}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={251.43} y={321.89} width={49.67} height={25.98} id='banner_svg__SVGID_156_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_48_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_49_'
            filterUnits='userSpaceOnUse'
            x={251.43}
            y={321.89}
            width={49.67}
            height={25.98}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_157_'
          gradientUnits='userSpaceOnUse'
          x1={251.841}
          y1={340.013}
          x2={301.504}
          y2={340.013}
          gradientTransform='rotate(.81 659.246 306.931)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M299.38 339.49c-5.01 6.71-19.41 10.08-32.18 7.54s-19.06-10.04-14.05-16.75 19.41-10.08 32.18-7.54 19.06 10.04 14.05 16.75z'
          mask='url(#banner_svg__SVGID_156_)'
          fill='url(#banner_svg__SVGID_157_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_158_'
      gradientUnits='userSpaceOnUse'
      x1={251.841}
      y1={340.013}
      x2={301.504}
      y2={340.013}
      gradientTransform='rotate(.81 659.246 306.931)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.547} stopColor='#daeaff' />
      <stop offset={0.999} stopColor='#d4e7ff' />
    </linearGradient>
    <path
      d='M299.38 339.49c-5.01 6.71-19.41 10.08-32.18 7.54s-19.06-10.04-14.05-16.75 19.41-10.08 32.18-7.54 19.06 10.04 14.05 16.75z'
      fill='url(#banner_svg__SVGID_158_)'
    />
    <linearGradient
      id='banner_svg__SVGID_159_'
      gradientUnits='userSpaceOnUse'
      x1={-492.515}
      y1={239.754}
      x2={-486.532}
      y2={239.754}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M251.67 241.53s-.25-2.58-.94-2.55c-.69.03-3.03-4.08-3.64-3.86-.62.23 1.72 3.51.91 3.92-.81.41-2.84 1.98-1.76 3.06 1.08 1.08 3.33 2.3 3.33 2.3l2.34-1.17-.24-1.7z'
      fill='url(#banner_svg__SVGID_159_)'
    />
    <path
      className='banner_svg__st182'
      d='M275.48 336.91s1.42 4.6-2.91 4.73c-2.82.08-8.68-3.93-8.43-4.79.25-.85 6.32-.06 6.32-.06l2.44-1.23 2.58 1.35zM283.95 333.62s1.42 4.6-2.91 4.73c-2.82.08-8.68-3.93-8.43-4.79.25-.85 6.32-.06 6.32-.06l2.44-1.23 2.58 1.35z'
    />
    <linearGradient
      id='banner_svg__SVGID_160_'
      gradientUnits='userSpaceOnUse'
      x1={-518.574}
      y1={318.445}
      x2={-509.492}
      y2={318.445}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M277.98 302.65l-2.12 34.18s-.65.8-3.58.55c-2.28-.19-2.68-.7-2.68-.7l-.71-33.28 1.87-3.93 7.22 3.18z'
      fill='url(#banner_svg__SVGID_160_)'
    />
    <linearGradient
      id='banner_svg__SVGID_161_'
      gradientUnits='userSpaceOnUse'
      x1={-527.041}
      y1={315.157}
      x2={-517.958}
      y2={315.157}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M286.44 299.36l-2.12 34.18s-.65.8-3.58.55c-2.28-.19-2.68-.7-2.68-.7l-.71-33.28 1.87-3.93 7.22 3.18z'
      fill='url(#banner_svg__SVGID_161_)'
    />
    <linearGradient
      id='banner_svg__SVGID_162_'
      gradientUnits='userSpaceOnUse'
      x1={-533.785}
      y1={261.889}
      x2={-520.091}
      y2={261.889}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={0.155} stopColor='#dae6ff' />
      <stop offset={0.481} stopColor='#c0d6ff' />
      <stop offset={0.773} stopColor='#b0ccff' />
      <stop offset={1} stopColor='#aac9ff' />
    </linearGradient>
    <path
      d='M286.8 252.38s6.11 7.88 6.38 12.18c.26 4.3-4.42 6.85-4.42 6.85s-1.97-1.46-2.16-1.61c-.18-.16-7.16-12.7-7.1-12.9.05-.22 7.3-4.52 7.3-4.52z'
      fill='url(#banner_svg__SVGID_162_)'
    />
    <linearGradient
      id='banner_svg__SVGID_163_'
      gradientUnits='userSpaceOnUse'
      x1={-519.075}
      y1={246.308}
      x2={-512.599}
      y2={246.308}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M278.01 245.45l.27 4.35-5.31-.3s-.24-4.42-.6-4.73l-.36-.31.42-.68 6.06-.95-.48 2.62z'
      fill='url(#banner_svg__SVGID_163_)'
    />
    <linearGradient
      id='banner_svg__SVGID_164_'
      gradientUnits='userSpaceOnUse'
      x1={-517.394}
      y1={241.064}
      x2={-509.737}
      y2={241.064}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#ff7f83' />
      <stop offset={1} stopColor='#ffd4d5' />
    </linearGradient>
    <path
      d='M270.21 236.75s-1.11 2.57-1.08 5.31c.03 2.74 1.08 4.34 1.08 4.34s3.85.83 5.41-.62 1.11-3.05 1.11-3.05l-.73-2.39-2.01-4.65-1.63-.17-2.15 1.23z'
      fill='url(#banner_svg__SVGID_164_)'
    />
    <linearGradient
      id='banner_svg__SVGID_165_'
      gradientUnits='userSpaceOnUse'
      x1={-530.862}
      y1={273.182}
      x2={-488.334}
      y2={273.182}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#e9efff' />
      <stop offset={1} stopColor='#d4e3ff' />
    </linearGradient>
    <path
      d='M247.74 244.52s10.55 17.48 12.14 19.21c.79.86 2.48.44 3.98-.2.03-.02.07-.03.1-.05.03-.01.07-.03.1-.05 1.43-.64 2.63-1.44 2.63-1.44.06 1.3.12 2.62.14 3.92 0 .23.01.47.01.7.02 6.04-.59 12.18-1.34 18.97-.75 6.8 0 17.97 0 17.97s1.02.87 6.49 1.63c5.47.76 10.1-2.05 10.1-2.05l1.67-4.74.78 3.62s.86.18 2.81-.36c1.95-.55 2.91-1.33 2.91-1.33s-.49-23.41-1.51-29.79c-.4-2.5-.55-4.54-.65-6.5-.15-3.03-.14-5.83-.69-9.74-.1-.72-.32-1.35-.62-1.92-.92-1.74-2.68-2.82-4.5-3.49-3.98-1.48-8.46-1.16-12.13.99-.35.21-.7.43-1.04.68 0 0-.05.03-.13.09-.68.45-3.85 2.57-5.68 3.85-.32.22-.6.41-.81.57-.45.32-1.42-.59-2.6-2.09v-.01c-3.1-3.95-7.6-11.92-7.6-11.92s-1.91-.03-3.16.77c-1.23.8-1.4 2.71-1.4 2.71z'
      fill='url(#banner_svg__SVGID_165_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_50_'
        filterUnits='userSpaceOnUse'
        x={264.06}
        y={260.32}
        width={4.25}
        height={5.6}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={264.06} y={260.32} width={4.25} height={5.6} id='banner_svg__SVGID_166_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_50_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_51_'
            filterUnits='userSpaceOnUse'
            x={264.06}
            y={260.32}
            width={4.25}
            height={5.6}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_167_'
          gradientUnits='userSpaceOnUse'
          x1={-508.915}
          y1={263.118}
          x2={-504.663}
          y2={263.118}
          gradientTransform='matrix(-1 0 0 1 -240.599 0)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M264.06 263.44c1.43-.64 2.63-1.44 2.63-1.44.06 1.3.12 2.62.14 3.92 0 0 2.47-5.96 1.02-5.58-1.4.36-2.7 2.57-3.79 3.1z'
          mask='url(#banner_svg__SVGID_166_)'
          fill='url(#banner_svg__SVGID_167_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_168_'
      gradientUnits='userSpaceOnUse'
      x1={-508.915}
      y1={263.118}
      x2={-504.663}
      y2={263.118}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M264.06 263.44c1.43-.64 2.63-1.44 2.63-1.44.06 1.3.12 2.62.14 3.92 0 0 2.47-5.96 1.02-5.58-1.4.36-2.7 2.57-3.79 3.1z'
      fill='url(#banner_svg__SVGID_168_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_52_'
        filterUnits='userSpaceOnUse'
        x={259.92}
        y={252.98}
        width={3.41}
        height={3.34}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={259.92} y={252.98} width={3.41} height={3.34} id='banner_svg__SVGID_169_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_52_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_53_'
            filterUnits='userSpaceOnUse'
            x={259.92}
            y={252.98}
            width={3.41}
            height={3.34}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_170_'
          gradientUnits='userSpaceOnUse'
          x1={-503.923}
          y1={254.644}
          x2={-500.514}
          y2={254.644}
          gradientTransform='matrix(-1 0 0 1 -240.599 0)'
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M259.92 252.98c.06.2 1.01 3.28 2.07 3.33 1.09.05 1.34-1.82 1.34-1.82-.32.22-.6.41-.81.57-.45.33-1.43-.59-2.6-2.08z'
          mask='url(#banner_svg__SVGID_169_)'
          fill='url(#banner_svg__SVGID_170_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_171_'
      gradientUnits='userSpaceOnUse'
      x1={-503.923}
      y1={254.644}
      x2={-500.514}
      y2={254.644}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path
      d='M259.92 252.98c.06.2 1.01 3.28 2.07 3.33 1.09.05 1.34-1.82 1.34-1.82-.32.22-.6.41-.81.57-.45.33-1.43-.59-2.6-2.08z'
      fill='url(#banner_svg__SVGID_171_)'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_54_'
        filterUnits='userSpaceOnUse'
        x={282.93}
        y={252.37}
        width={7.33}
        height={49.68}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={282.93} y={252.37} width={7.33} height={49.68} id='banner_svg__SVGID_172_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_54_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_55_'
            filterUnits='userSpaceOnUse'
            x={282.93}
            y={252.37}
            width={7.33}
            height={49.68}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_173_'
          gradientUnits='userSpaceOnUse'
          x1={-530.862}
          y1={277.211}
          x2={-523.528}
          y2={277.211}
          gradientTransform='matrix(-1 0 0 1 -240.599 0)'
        >
          <stop offset={0.001} stopColor='#fff' />
          <stop offset={0.113} stopColor='#eaeaea' />
          <stop offset={0.345} stopColor='#b3b3b3' />
          <stop offset={0.673} stopColor='#5d5d5d' />
          <stop offset={1} />
        </linearGradient>
        <path
          d='M286.1 294.39c2.29 2.3-1.56 7.63-1.56 7.63s.86.18 2.81-.36c1.95-.55 2.91-1.33 2.91-1.33s-.49-23.41-1.51-29.79c-.4-2.5-.55-4.54-.65-6.5-.15-3.03-.14-5.83-.69-9.74-.1-.72-.32-1.35-.62-1.92 0 0 .18 13.1-2.41 14.03-2.99 1.07-.7 2.12.88 4.32 1.77 2.46 3.97 15.77.62 17.81-2.96 1.81-2.07 3.56.22 5.85z'
          mask='url(#banner_svg__SVGID_172_)'
          fill='url(#banner_svg__SVGID_173_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_174_'
      gradientUnits='userSpaceOnUse'
      x1={-530.862}
      y1={277.211}
      x2={-523.528}
      y2={277.211}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0.001} stopColor='#bfccff' />
      <stop offset={0.543} stopColor='#d4dfff' />
      <stop offset={1} stopColor='#e9f2ff' />
    </linearGradient>
    <path
      d='M286.1 294.39c2.29 2.3-1.56 7.63-1.56 7.63s.86.18 2.81-.36c1.95-.55 2.91-1.33 2.91-1.33s-.49-23.41-1.51-29.79c-.4-2.5-.55-4.54-.65-6.5-.15-3.03-.14-5.83-.69-9.74-.1-.72-.32-1.35-.62-1.92 0 0 .18 13.1-2.41 14.03-2.99 1.07-.7 2.12.88 4.32 1.77 2.46 3.97 15.77.62 17.81-2.96 1.81-2.07 3.56.22 5.85z'
      fill='url(#banner_svg__SVGID_174_)'
    />
    <linearGradient
      id='banner_svg__SVGID_175_'
      gradientUnits='userSpaceOnUse'
      x1={-510.601}
      y1={281.425}
      x2={-508.343}
      y2={281.425}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M269.81 282.28l.19-.42c-1.26-.58-1.91-1.28-1.91-1.29l-.34.31c.02.03.71.78 2.06 1.4z'
      fill='url(#banner_svg__SVGID_175_)'
    />
    <linearGradient
      id='banner_svg__SVGID_176_'
      gradientUnits='userSpaceOnUse'
      x1={-514.957}
      y1={279.923}
      x2={-510.332}
      y2={279.923}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#f22aff' />
      <stop offset={1} stopColor='#6c00e9' />
    </linearGradient>
    <path
      d='M269.73 276.92v5.36c.3.14.64.27 1.02.38 1.04.31 2.47.3 3.61.22v-4.83l-4.63-1.13z'
      fill='url(#banner_svg__SVGID_176_)'
    />
    <linearGradient
      id='banner_svg__SVGID_177_'
      gradientUnits='userSpaceOnUse'
      x1={-516.77}
      y1={282.521}
      x2={-514.863}
      y2={282.521}
      gradientTransform='matrix(-1 0 0 1 -240.599 0)'
    >
      <stop offset={0} stopColor='#6aaeff' />
      <stop offset={1} stopColor='#005eff' />
    </linearGradient>
    <path
      d='M274.3 282.86c1.05-.08 1.84-.21 1.87-.22l-.08-.45c-.01 0-.8.14-1.83.21l.04.46z'
      fill='url(#banner_svg__SVGID_177_)'
    />
    <path
      className='banner_svg__st182'
      d='M270.63 231.41s-1.77 3.71-.42 5.34 3.16 2.17 3.26 3.3c.1 1.12-.69.9-.95 1.66-.26.75.18 1.56.18 1.56s1.51-1.59 2.21-.57c.7 1.01.73 2 0 2.37-.73.36-1.38 0-1.43.52-.05.52 1.17 2.08 3.51 1.4 2.34-.68 3.38-1.74 3.69-3.33.31-1.59-.62-3.54-.62-3.54s1.63-3.71-.35-5.1c-1.98-1.39-3.26-.73-4.2-1.08-.94-.35-.28-1.6-1.14-1.84-.87-.24-1.39 1.77-2.08 1.6-.69-.17-.06-2.36-.59-3.3-.52-.94-1.07 1.01-1.07 1.01z'
    />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_56_'
        filterUnits='userSpaceOnUse'
        x={66.2}
        y={256.99}
        width={1.61}
        height={66.77}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={66.2} y={256.99} width={1.61} height={66.77} id='banner_svg__SVGID_178_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_56_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_57_'
            filterUnits='userSpaceOnUse'
            x={66.2}
            y={256.99}
            width={1.61}
            height={66.77}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_179_'
          gradientUnits='userSpaceOnUse'
          x1={66.202}
          y1={290.377}
          x2={67.813}
          y2={290.377}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M66.2 256.99s.55 34.43.81 66.77c0 0 .57-52.1.81-66.77H66.2z'
          mask='url(#banner_svg__SVGID_178_)'
          fill='url(#banner_svg__SVGID_179_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_180_'
      gradientUnits='userSpaceOnUse'
      x1={66.202}
      y1={290.377}
      x2={67.813}
      y2={290.377}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M66.2 256.99s.55 34.43.81 66.77c0 0 .57-52.1.81-66.77H66.2z' fill='url(#banner_svg__SVGID_180_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_58_'
        filterUnits='userSpaceOnUse'
        x={61.05}
        y={246.31}
        width={11.92}
        height={11.92}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={61.05} y={246.31} width={11.92} height={11.92} id='banner_svg__SVGID_181_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_58_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_59_'
            filterUnits='userSpaceOnUse'
            x={61.05}
            y={246.31}
            width={11.92}
            height={11.92}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_182_'
          gradientUnits='userSpaceOnUse'
          x1={72.969}
          y1={252.273}
          x2={61.046}
          y2={252.273}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={67.01}
          cy={252.27}
          r={5.96}
          mask='url(#banner_svg__SVGID_181_)'
          fill='url(#banner_svg__SVGID_182_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_183_'
      gradientUnits='userSpaceOnUse'
      x1={72.969}
      y1={252.273}
      x2={61.046}
      y2={252.273}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={67.01} cy={252.27} r={5.96} fill='url(#banner_svg__SVGID_183_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_60_'
        filterUnits='userSpaceOnUse'
        x={317.57}
        y={241.78}
        width={1.61}
        height={91.5}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={317.57} y={241.78} width={1.61} height={91.5} id='banner_svg__SVGID_184_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_60_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_61_'
            filterUnits='userSpaceOnUse'
            x={317.57}
            y={241.78}
            width={1.61}
            height={91.5}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_185_'
          gradientUnits='userSpaceOnUse'
          x1={317.567}
          y1={287.524}
          x2={319.179}
          y2={287.524}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M317.57 241.78s.55 47.18.81 91.5c0 0 .57-71.39.81-91.5h-1.62z'
          mask='url(#banner_svg__SVGID_184_)'
          fill='url(#banner_svg__SVGID_185_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_186_'
      gradientUnits='userSpaceOnUse'
      x1={317.567}
      y1={287.524}
      x2={319.179}
      y2={287.524}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M317.57 241.78s.55 47.18.81 91.5c0 0 .57-71.39.81-91.5h-1.62z' fill='url(#banner_svg__SVGID_186_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_62_'
        filterUnits='userSpaceOnUse'
        x={312.41}
        y={231.1}
        width={11.92}
        height={11.92}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={312.41} y={231.1} width={11.92} height={11.92} id='banner_svg__SVGID_187_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_62_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_63_'
            filterUnits='userSpaceOnUse'
            x={312.41}
            y={231.1}
            width={11.92}
            height={11.92}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_188_'
          gradientUnits='userSpaceOnUse'
          x1={324.335}
          y1={237.058}
          x2={312.411}
          y2={237.058}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={318.37}
          cy={237.06}
          r={5.96}
          mask='url(#banner_svg__SVGID_187_)'
          fill='url(#banner_svg__SVGID_188_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_189_'
      gradientUnits='userSpaceOnUse'
      x1={324.335}
      y1={237.058}
      x2={312.411}
      y2={237.058}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={318.37} cy={237.06} r={5.96} fill='url(#banner_svg__SVGID_189_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_64_'
        filterUnits='userSpaceOnUse'
        x={222.62}
        y={307.87}
        width={1.61}
        height={54.74}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={222.62} y={307.87} width={1.61} height={54.74} id='banner_svg__SVGID_190_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_64_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_65_'
            filterUnits='userSpaceOnUse'
            x={222.62}
            y={307.87}
            width={1.61}
            height={54.74}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_191_'
          gradientUnits='userSpaceOnUse'
          x1={222.623}
          y1={335.236}
          x2={224.234}
          y2={335.236}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M222.62 307.87s.55 28.22.81 54.74c0 0 .57-42.71.81-54.74h-1.62z'
          mask='url(#banner_svg__SVGID_190_)'
          fill='url(#banner_svg__SVGID_191_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_192_'
      gradientUnits='userSpaceOnUse'
      x1={222.623}
      y1={335.236}
      x2={224.234}
      y2={335.236}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M222.62 307.87s.55 28.22.81 54.74c0 0 .57-42.71.81-54.74h-1.62z' fill='url(#banner_svg__SVGID_192_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_66_'
        filterUnits='userSpaceOnUse'
        x={217.47}
        y={297.19}
        width={11.92}
        height={11.92}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={217.47} y={297.19} width={11.92} height={11.92} id='banner_svg__SVGID_193_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_66_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_67_'
            filterUnits='userSpaceOnUse'
            x={217.47}
            y={297.19}
            width={11.92}
            height={11.92}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_194_'
          gradientUnits='userSpaceOnUse'
          x1={229.391}
          y1={303.149}
          x2={217.467}
          y2={303.149}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={223.43}
          cy={303.15}
          r={5.96}
          mask='url(#banner_svg__SVGID_193_)'
          fill='url(#banner_svg__SVGID_194_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_195_'
      gradientUnits='userSpaceOnUse'
      x1={229.391}
      y1={303.149}
      x2={217.467}
      y2={303.149}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={223.43} cy={303.15} r={5.96} fill='url(#banner_svg__SVGID_195_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_68_'
        filterUnits='userSpaceOnUse'
        x={395.17}
        y={266.17}
        width={1.61}
        height={54.74}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={395.17} y={266.17} width={1.61} height={54.74} id='banner_svg__SVGID_196_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_68_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_69_'
            filterUnits='userSpaceOnUse'
            x={395.17}
            y={266.17}
            width={1.61}
            height={54.74}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_197_'
          gradientUnits='userSpaceOnUse'
          x1={395.171}
          y1={293.534}
          x2={396.782}
          y2={293.534}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <path
          d='M395.17 266.17s.55 28.22.81 54.74c0 0 .57-42.71.81-54.74h-1.62z'
          mask='url(#banner_svg__SVGID_196_)'
          fill='url(#banner_svg__SVGID_197_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_198_'
      gradientUnits='userSpaceOnUse'
      x1={395.171}
      y1={293.534}
      x2={396.782}
      y2={293.534}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <path d='M395.17 266.17s.55 28.22.81 54.74c0 0 .57-42.71.81-54.74h-1.62z' fill='url(#banner_svg__SVGID_198_)' />
    <defs>
      <filter
        id='banner_svg__Adobe_OpacityMaskFilter_70_'
        filterUnits='userSpaceOnUse'
        x={390.02}
        y={255.49}
        width={11.92}
        height={11.92}
      >
        <feFlood result='back' floodColor='#fff' floodOpacity={1} />
        <feBlend in='SourceGraphic' in2='back' />
      </filter>
    </defs>
    <mask maskUnits='userSpaceOnUse' x={390.02} y={255.49} width={11.92} height={11.92} id='banner_svg__SVGID_199_'>
      <g filter='url(#banner_svg__Adobe_OpacityMaskFilter_70_)'>
        <defs>
          <filter
            id='banner_svg__Adobe_OpacityMaskFilter_71_'
            filterUnits='userSpaceOnUse'
            x={390.02}
            y={255.49}
            width={11.92}
            height={11.92}
          >
            <feFlood result='back' floodColor='#fff' floodOpacity={1} />
            <feBlend in='SourceGraphic' in2='back' />
          </filter>
        </defs>
        <linearGradient
          id='banner_svg__SVGID_200_'
          gradientUnits='userSpaceOnUse'
          x1={401.939}
          y1={261.447}
          x2={390.015}
          y2={261.447}
        >
          <stop offset={0} stopColor='#666' />
          <stop offset={0.056} stopColor='#737373' />
          <stop offset={0.284} stopColor='#a5a5a5' />
          <stop offset={0.5} stopColor='#ccc' />
          <stop offset={0.698} stopColor='#e8e8e8' />
          <stop offset={0.87} stopColor='#f9f9f9' />
          <stop offset={0.999} stopColor='#fff' />
        </linearGradient>
        <circle
          cx={395.98}
          cy={261.45}
          r={5.96}
          mask='url(#banner_svg__SVGID_199_)'
          fill='url(#banner_svg__SVGID_200_)'
        />
      </g>
    </mask>
    <linearGradient
      id='banner_svg__SVGID_201_'
      gradientUnits='userSpaceOnUse'
      x1={401.939}
      y1={261.447}
      x2={390.015}
      y2={261.447}
    >
      <stop offset={0} stopColor='#e9f2ff' />
      <stop offset={0.277} stopColor='#c7dbff' />
      <stop offset={0.562} stopColor='#abc8ff' />
      <stop offset={0.812} stopColor='#9abdff' />
      <stop offset={0.999} stopColor='#94b9ff' />
    </linearGradient>
    <circle cx={395.98} cy={261.45} r={5.96} fill='url(#banner_svg__SVGID_201_)' />
  </svg>
)

export default SvgBanner
