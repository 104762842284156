import React from 'react'

const SvgSdWanFeature3 = props => (
  <svg id='sd-wan-feature3_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>
      {
        '.sd-wan-feature3_svg__st0{fill:#334966}.sd-wan-feature3_svg__st1{fill:#fd5c1f}.sd-wan-feature3_svg__st2{fill:none;stroke:#334966;stroke-width:2}'
      }
    </style>
    <g id='sd-wan-feature3_svg__\u5B8C\u5168\u6258\u7BA1'>
      <path
        id='sd-wan-feature3_svg__Rectangle-16'
        className='sd-wan-feature3_svg__st0'
        d='M3 6v32h44V6H3zM1 4h48v36H1V4z'
      />
      <path id='sd-wan-feature3_svg__Rectangle-17_1_' className='sd-wan-feature3_svg__st0' d='M13 44h24v2H13z' />
      <path id='sd-wan-feature3_svg__Rectangle-73_1_' className='sd-wan-feature3_svg__st0' d='M21 38h2v8h-2z' />
      <path id='sd-wan-feature3_svg__Rectangle-73-Copy' className='sd-wan-feature3_svg__st0' d='M27 38h2v8h-2z' />
      <path id='sd-wan-feature3_svg__Rectangle-74' className='sd-wan-feature3_svg__st1' d='M13 15h8v18h-8z' />
      <path id='sd-wan-feature3_svg__Rectangle-74-Copy' className='sd-wan-feature3_svg__st1' d='M29 11h8v22h-8z' />
      <path id='sd-wan-feature3_svg__Path-5' className='sd-wan-feature3_svg__st2' d='M10 31l12-12 11 2 7-7' />
      <path
        id='sd-wan-feature3_svg__Path-5-Copy'
        className='sd-wan-feature3_svg__st2'
        d='M10 19l15 12 7.85-4.12L40 28'
      />
    </g>
  </svg>
)

export default SvgSdWanFeature3
