import React from 'react'

const SvgSdWanFeature1 = props => (
  <svg id='sd-wan-feature1_svg__\u56FE\u5C42_1' x={0} y={0} viewBox='0 0 50 50' xmlSpace='preserve' {...props}>
    <style>{'.sd-wan-feature1_svg__st2{fill:#334966}'}</style>
    <g id='sd-wan-feature1_svg__\u65E0\u6027\u80FD\u635F\u8017'>
      <circle id='sd-wan-feature1_svg__Oval-7_5_' cx={25} cy={25} r={23} fill='none' stroke='#334966' strokeWidth={2} />
      <path
        id='sd-wan-feature1_svg__Rectangle-85'
        d='M10 28c3.15-.16 5.15-.49 6-1 3.75-2.22 6.83-5 9-5 2.22 0 5.12 2.67 9 5 .8.48 2.8.82 6 1v4H10v-4z'
        fill='#fd5c1f'
      />
      <path id='sd-wan-feature1_svg__Rectangle-84' className='sd-wan-feature1_svg__st2' d='M18 16h2v16h-2z' />
      <path id='sd-wan-feature1_svg__Rectangle-84-Copy' className='sd-wan-feature1_svg__st2' d='M30 16h2v16h-2z' />
      <path id='sd-wan-feature1_svg__Rectangle-84-Copy-3' className='sd-wan-feature1_svg__st2' d='M36 22h2v10h-2z' />
      <path id='sd-wan-feature1_svg__Rectangle-84-Copy-4' className='sd-wan-feature1_svg__st2' d='M12 22h2v10h-2z' />
      <path id='sd-wan-feature1_svg__Rectangle-84-Copy-2' className='sd-wan-feature1_svg__st2' d='M24 20h2v12h-2z' />
    </g>
  </svg>
)

export default SvgSdWanFeature1
