import React from 'react'
import intl from 'react-intl-universal'
import { SolutionBanner, InviteBanner } from '../../components/banners'
import { CommonTitle } from '../../components/titles'
import { Swiper, Wrapper } from '@raysync/common/components'
import Layout from '../../components/layout'

import { ApplicationSection } from './atoms'
import { FeatureSection, ArchSection, NetworkBox, NetworkBoxOne, NetworkBoxTwo, NetworkBigBox } from './sd-wan.atoms'

import List from '../../components/list'

import Feature1 from '@raysync/common/components/svg/network/sd-wan/sd-wan-feature1'
import Feature2 from '@raysync/common/components/svg/network/sd-wan/sd-wan-feature2'
import Feature3 from '@raysync/common/components/svg/network/sd-wan/sd-wan-feature3'
import Feature4 from '@raysync/common/components/svg/network/sd-wan/sd-wan-feature4'

import Arch from '@raysync/common/components/svg/network/sd-wan/application-scenario'

import Banner from '@raysync/common/components/svg/network/sd-wan/banner'

class SD extends React.PureComponent {
  state = {
    //产品特性列表
    featureList: [
      {
        title: intl.get('network2.feature.item1.title'),
        desc: intl.get('network2.feature.item1.desc'),
        svg: <Feature1 />,
      },
      {
        title: intl.get('network2.feature.item2.title'),
        desc: intl.get('network2.feature.item2.desc'),
        svg: <Feature2 />,
      },
      {
        title: intl.get('network2.feature.item3.title'),
        desc: intl.get('network2.feature.item3.desc'),
        svg: <Feature3 />,
      },
      {
        title: intl.get('network2.feature.item4.title'),
        desc: intl.get('network2.feature.item4.desc'),
        svg: <Feature4 />,
      },
    ],
    //产品优势列表1
    featureListTwo: [
      {
        title: intl.get('network2.feature1.title'),
      },
      {
        title: intl.get('network2.feature2.title'),
      },
      {
        title: intl.get('network2.feature3.title'),
      },
      {
        title: intl.get('network2.feature4.title'),
      },
    ],
    //产品优势列表2
    featureListThree: [
      {
        title1: intl.get('network2.feature5.title1'),
        title2: intl.get('network2.feature5.title2'),
        title3: intl.get('network2.feature5.title3'),
        title4: intl.get('network2.feature5.title4'),
      },
      {
        title1: intl.get('network2.feature6.title1'),
        title2: intl.get('network2.feature6.title2'),
        title3: intl.get('network2.feature6.title3'),
        title4: intl.get('network2.feature6.title4'),
      },
      {
        title1: intl.get('network2.feature7.title1'),
        title2: intl.get('network2.feature7.title2'),
        title3: intl.get('network2.feature7.title3'),
        title4: intl.get('network2.feature7.title4'),
      },
      {
        title1: intl.get('network2.feature8.title1'),
        title2: intl.get('network2.feature8.title2'),
        title3: intl.get('network2.feature8.title3'),
        title4: intl.get('network2.feature8.title4'),
      },
      {
        title1: intl.get('network2.feature9.title1'),
        title2: intl.get('network2.feature9.title2'),
        title3: intl.get('network2.feature9.title3'),
        title4: intl.get('network2.feature9.title4'),
      },
      {
        title1: intl.get('network2.feature10.title1'),
        title2: intl.get('network2.feature10.title2'),
        title3: intl.get('network2.feature10.title3'),
        title4: intl.get('network2.feature10.title4'),
      },
      {
        title1: intl.get('network2.feature11.title1'),
        title2: intl.get('network2.feature11.title2'),
        title3: intl.get('network2.feature11.title3'),
        title4: intl.get('network2.feature11.title4'),
      },
      {
        title1: intl.get('network2.feature12.title1'),
        title2: intl.get('network2.feature12.title2'),
        title3: intl.get('network2.feature12.title3'),
        title4: intl.get('network2.feature12.title4'),
      },
      {
        title1: intl.get('network2.feature13.title1'),
        title2: intl.get('network2.feature13.title2'),
        title3: intl.get('network2.feature13.title3'),
        title4: intl.get('network2.feature13.title4'),
      },
    ],
  }
  render() {
    const { location } = this.props
    const { featureList, featureListTwo, featureListThree } = this.state
    const ArchPic = <Arch />

    return (
      <Layout pageType='network2' location={location}>
        {/* 第一屏(SD-WAN) */}
        <Swiper>
          <SolutionBanner type='network2' pic={<Banner />} scaled />
        </Swiper>

        {/* 第二屏(产品特性) */}
        <FeatureSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network2.feature.title')}</CommonTitle>
            <List data={featureList} />
          </Wrapper>
        </FeatureSection>

        {/* 第三屏(产品优势) */}
        <ApplicationSection>
          <Wrapper>
            <CommonTitle bottom={40}>{intl.get('network2.scenes.title')}</CommonTitle>
            <NetworkBigBox>
              <NetworkBoxOne>
                {featureListTwo.map(l => (
                  <span>{l.title}</span>
                ))}
              </NetworkBoxOne>
              <NetworkBox>
                {featureListThree.map((l, i) => (
                  <NetworkBoxTwo key={i}>
                    <span>{l.title1}</span>
                    <span>{l.title2}</span>
                    <span>{l.title3}</span>
                    <span>{l.title4}</span>
                  </NetworkBoxTwo>
                ))}
              </NetworkBox>
            </NetworkBigBox>
          </Wrapper>
        </ApplicationSection>

        {/* 第四屏(应用场景) */}
        <ArchSection>
          <Wrapper>
            <CommonTitle>{intl.get('network2.arch.title')}</CommonTitle>
            <div style={{ margin: '0 90px' }}>{ArchPic}</div>
          </Wrapper>
        </ArchSection>

        {/* 第五屏(申请免费试用) */}
        <Swiper>
          <InviteBanner type='network2' />
        </Swiper>
      </Layout>
    )
  }
}

export default SD
